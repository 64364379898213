import { useEffect } from 'react';

let currentPath = document.referrer || null;
let previousPath = null;

const usePreviousLocation = () => {
  useEffect(() => {
    if (window.location.href !== currentPath) {
      previousPath = currentPath;
      currentPath = window.location.href;
    }
  }, [window.location.href]);

  return {
    previousPath,
    currentPath,
  };
};

export {
  usePreviousLocation,
};
