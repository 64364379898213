import './style/index.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'components/Loader';

export function LoadingScreen() {
  return (
    <div className="LoadingScreen">
      <div className="content">
        <Loader />
        <div className="label">
          <FormattedMessage id="loading" />
        </div>
      </div>
    </div>
  );
}
