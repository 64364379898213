import { toast } from 'react-toastify';
import { ReactComponent as OfflineIcon } from 'icons/offline.svg';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { FormattedMessage } from 'react-intl';
import { post } from 'utils/query';

export function displayError(e, cb = () => {}) {
  if ((e instanceof TypeError && e.message === 'Failed to fetch') || (e instanceof DOMException)) {
    // Fetch query aborted / canceled / network problem
    return toast.error(<FormattedMessage id="network.error" />, {
      icon: <OfflineIcon />,
      // Back anyway after the toast display
      onClose: cb,
    });
  }

  if (e.content) {
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      const result = JSON.parse(reader.result);

      const icon = <InfoIcon />;
      let message = <FormattedMessage id="experience.submit.error" />;
      let shouldRedirect = true;

      switch (e.status) {
        case 403:
        case 404:
          message = <FormattedMessage id={`error.${e.status}`} />;

          break;

        case 400:
        case 422:
          message = <>
            <FormattedMessage id={`error.${e.status}`} />

            {result?.violations && <ul>
              {result.violations.map(violation => <li key={violation.code}>{violation.propertyPath}: {violation.message}</li>)}
            </ul>}
          </>;
          shouldRedirect = false;

          break;

        default:
          message = <FormattedMessage id="error.500" />;

          break;
      }

      toast.error(message, {
        icon,
        // Back after the toast display, only if the error is not field related
        onClose: shouldRedirect && cb,
      });

    });

    if (e.content instanceof Blob) {
      reader.readAsText(e.content);

      return;
    }
  }

  logError(e);

  toast.error(<FormattedMessage id="error.default" />, {
    icon: <InfoIcon />,
    // Back after the toast display, only if the error is not field related
    onClose: cb,
  });
}

export const logError = async(error) => {
  console.error('logError', JSON.stringify(error?.message ? { error: error.message, info: error.stack, info2: error.componentStack } : error));

  if (window.newrelic) {
    window.newrelic.noticeError(error);
  }

  return await post('/api/front_logs', {
    data: {
      environment: `web-contrib-${process.env.REACT_APP_ENV_NAME}`,
      message: JSON.stringify(error.message ? { error: error.message, info: error.stack, info2: error.componentStack } : error),
      userAgent: window?.navigator?.userAgent,
    },
  });
};
