import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Btn } from 'components/Btn';
import { useNavigate } from 'react-router-dom';
import { redirect } from 'utils/device';
import { FormattedMessage } from 'react-intl';

export function ErrorScreen({ children }) {
  const navigate = useNavigate();

  return (
    <div className="ErrorScreen">
      <div className="content">
        {children}

        <Btn onClick={() => redirect(navigate, true)}>
          <FormattedMessage id="back" />
        </Btn>
      </div>
    </div>
  );
}

ErrorScreen.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
};
