import './style/index.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Btn } from 'components/Btn';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as GlobeIcon } from 'icons/globe.svg';
import { ReactComponent as FacebookIcon } from 'icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'icons/twitter.svg';
import { Field } from 'react-final-form';
import { FieldInput } from 'components/FieldInput';
import { useValidators } from 'hooks/useValidators';
import { AvatarDisplay } from './AvatarDisplay';
import { Variant1 } from './Variant1';
import { FieldTextarea } from 'components/FieldTextarea';
import { FieldUploadAvatar } from 'components/FieldUploadAvatar';

export function Contributor({ user, variant, editable, submitting }) {
  const { firstName, avatar, contributor } = user;
  const classNames = [
    'Contributor',
    variant && `variant-${variant}`,
  ].filter(Boolean);

  const [ isEditOpen, setIsEditOpen ] = useState(false);
  const [ isSocialOpen, setIsSocialOpen ] = useState(false);

  const { required, url } = useValidators();
  const intl = useIntl();

  useEffect(() => {
    setIsEditOpen(false);
    setIsSocialOpen(false);
  }, [submitting]);

  if (variant === 1) {
    return <Variant1 user={user} classNames={classNames} />;
  }

  const renderName = () => {
    if (isEditOpen) {
      return <FormattedMessage id="contributor.profile" />;
    }

    return firstName ? (
      <>
        <FormattedMessage id="createdBy.prefix" />
        <br/>
        <FormattedMessage id="createdBy" values={{ name: firstName }} />
      </>
    ) : (
      <>
        <FormattedMessage id="createdBy.prefix" />
        &nbsp;
        <FormattedMessage id="createdBy" values={{ name: '' }} />
      </>
    );
  };

  return (
    <div className={classNames.join(' ')}>
      <header>
        <div className="name">
          <h2>
            {renderName()}
          </h2>
        </div>

        {!isEditOpen && <AvatarDisplay avatar={avatar?.url ?? avatar} />}
      </header>

      {isEditOpen && (
        <Field
          name="user.avatar"
          component={FieldUploadAvatar}
          label={intl.formatMessage({ id: 'contributor.field.picture.label' })}
        />
      )}



      {((editable && !firstName) || isEditOpen) && (
        <Field
          name="user.firstName"
          component={FieldInput}
          validate={required}
          required
          maxLength={255}
          placeholder={intl.formatMessage({ id: 'firstName' })}
          label={intl.formatMessage({ id: 'firstName' })}
        />
      )}

      {!isEditOpen && (
        <>
          {contributor?.description && <div className="description">{contributor.description}</div>}

          <div className="social">
            {contributor?.socialMedia?.facebook && (
              <a className="icon" target="_blank" rel="noreferrer" href={contributor.socialMedia.facebook} alt="facebook">
                <FacebookIcon />
              </a>
            )}
            {contributor?.socialMedia?.instagram && (
              <a className="icon" target="_blank" rel="noreferrer" href={contributor.socialMedia.instagram} alt="instagram">
                <InstagramIcon />
              </a>
            )}
            {contributor?.socialMedia?.twitter && (
              <a className="icon" target="_blank" rel="noreferrer" href={contributor.socialMedia.twitter} alt="twitter">
                <TwitterIcon />
              </a>
            )}
            {contributor?.website && (
              <a className="icon" target="_blank" rel="noreferrer" href={contributor.website} alt="website">
                <GlobeIcon />
              </a>
            )}
          </div>
        </>
      )}

      {!isEditOpen && editable && (
        <Btn variant={4} onClick={() => setIsEditOpen(true)} className="edit">
          <span className="icon">
            <EditIcon />
          </span>
          <span className="label">
            <FormattedMessage id="contributor.edit" />
          </span>
        </Btn>
      )}

      {isEditOpen && (
        <>
          <Field
            name="user.contributor.description"
            component={FieldTextarea}
            maxLength={400}
            placeholder={intl.formatMessage({ id: 'contributor.field.description.placeholder' })}
            label={intl.formatMessage({ id: 'contributor.field.description.label' })}
          />

          {!isSocialOpen && (
            <Btn variant={4} onClick={() => setIsSocialOpen(true)} className="edit">
              <span className="icon">
                <PlusIcon />
              </span>
              <span className="label">
                <FormattedMessage id="contributor.social.edit" />
              </span>
            </Btn>
          )}

          {isSocialOpen && (
            <>
              <Field
                name="user.contributor.socialMedia.facebook"
                component={FieldInput}
                validate={url}
                placeholder="https://www.facebook.com/..."
                label={intl.formatMessage({ id: 'facebook' })}
              />
              <Field
                name="user.contributor.socialMedia.instagram"
                component={FieldInput}
                validate={url}
                placeholder="https://www.instagram.com/..."
                label={intl.formatMessage({ id: 'instagram' })}
              />
              <Field
                name="user.contributor.website"
                component={FieldInput}
                validate={url}
                placeholder="https://website.com/..."
                label={intl.formatMessage({ id: 'website' })}
              />
              <Field
                name="user.contributor.socialMedia.twitter"
                component={FieldInput}
                validate={url}
                placeholder="https://twitter.com/..."
                label={intl.formatMessage({ id: 'twitter' })}
              />
            </>
          )}

          <div className="shared-all">
            <span className="icon"><GlobeIcon /></span>
            <FormattedMessage id="shared.all" />
          </div>
        </>
      )}
    </div>
  );
}

Contributor.propTypes = {
  user: PropTypes.object.isRequired,
  variant: PropTypes.number,
  editable: PropTypes.bool,
  submitting: PropTypes.bool,
};
