import './style/index.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';

L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

export function Map({ geojson, point, className = 'Map' }) {
  const divRef = useRef();
  const mapRef = useRef();
  const geojsonLayer = useRef();
  const pointLayer = useRef();

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    mapRef.current = L.map(divRef.current, {
      preferCanvas: true,
      gestureHandling: true,
    });

    mapRef.current.setView(L.latLng(46.793, 3.450), 6);

    L.tileLayer('https://tiles.mhikes.com/mhikes/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://tiles.mhikes.com/">Mhikes</a>',
      maxZoom: 22,
    }).addTo(mapRef.current);
  }, []);

  useEffect(() => {
    if (geojsonLayer.current) {
      mapRef.current.removeLayer(geojsonLayer.current);
    }

    if (geojson) {
      geojsonLayer.current = L.geoJSON(geojson, {
        style: function(feature) {
          return {
            color: '#19494d',
            weight: 3,
          };
        }
      }).addTo(mapRef.current);

      const bounds = geojsonLayer.current.getBounds();

      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geojson]);

  useEffect(() => {
    if (pointLayer.current) {
      mapRef.current.removeLayer(pointLayer.current);
    }

    if (point?.length) {
      const [ y, x ] = point;
      const latLng = L.latLng(x, y);

      pointLayer.current = L.marker(latLng, {
        icon: L.icon({
          iconUrl: process.env.PUBLIC_URL + '/map/marker.png',
          iconSize: [ 24, 29 ],
          iconAnchor: [ 12, 29 ],
          popupAnchor: [ 0, 0 ],
        })
      }).addTo(mapRef.current);

      mapRef.current.setView(L.latLng(latLng), 8);
    }
  }, [point]);

  return (
    <div className={className} ref={divRef} />
  );
}

Map.propTypes = {
  geojson: PropTypes.object,
  point: PropTypes.array,
};
