import {
  checkStatus,
  parse,
  addStorageParams,
  defaultParams,
} from 'utils/query';

export default async function get(url, customParams = {}) {
  const headers = new Headers(defaultParams.headers);

  if (customParams.headers) {
    Object.keys(customParams.headers).forEach(k => headers.set(k, customParams.headers[k]));
  }

  const params = {
    ...defaultParams,
    ...customParams,
    headers,
    method: 'GET',
  };

  const { timeout = 60000 } = customParams;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  return fetch(url, { ...addStorageParams(params), signal: controller.signal })
    .then(response => {
      clearTimeout(id);

      return parse(response);
    })
    .then(checkStatus)
  ;
}
