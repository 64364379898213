import './style/index.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Btn } from 'components/Btn';
import Lottie from 'react-lottie-player';
import animation from 'lottie/thanks.json';
import { redirect } from 'utils/device';
import { useNavigate } from 'react-router-dom';
import { usePreviousLocation } from 'hooks/usePreviousLocation';

export function Thanks() {
  const navigate = useNavigate();

  usePreviousLocation();

  const onClose = () => {
    redirect(navigate);
  };

  return (
    <div className="Thanks">
      <section className="page-content thanks">
        <Lottie
          loop={false}
          animationData={animation}
          play
          style={{ width: '100%', height: 240 }}
        />

        <h1><FormattedMessage id="experience.thanks" /></h1>

        <div className="content">
          <div className="explanation-1">
            <FormattedMessage id="experience.thanks.explanation1" />
          </div>
          <div className="explanation-2">
            <FormattedMessage id="experience.thanks.explanation2" />
          </div>
          <div className="explanation-3">
            <FormattedMessage id="experience.thanks.explanation3" />
          </div>
        </div>

        <Btn variant={1} onClick={onClose}>
          <span className="label">
            <FormattedMessage id="close" />
          </span>
        </Btn>
      </section>

      <section className="page-content participate">
        <h2><FormattedMessage id="experience.participate" /></h2>

        <div className="content">
          <div className="explanation-1">
            <FormattedMessage id="experience.participate.explanation1" />
          </div>
          <div className="explanation-2">
            <FormattedMessage id="experience.participate.explanation2" />
          </div>
        </div>

        <Btn>
          <a className="label" href="https://decathlon-outdoor.typeform.com/ContribMVPBeta" target="_blank" rel="noreferrer">
            <FormattedMessage id="participate" />
          </a>
        </Btn>
      </section>
    </div>
  );
}
