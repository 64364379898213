import { useEffect, useRef, useReducer } from 'react';

const useFetch = (url, params = {}) => {
  const { token } = params;
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    error: null,
    result: null,
  };

  const [ state, dispatch ] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', result: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;

    if (!url) {
      return;
    }

    if (cache.current[url]) {
      dispatch({ type: 'FETCHED', payload: cache.current[url] });

      return;
    }

    const fetchData = async() => {
      dispatch({ type: 'FETCHING' });

      try {
        const headers = new Headers();

        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }

        const response = await fetch(url, {
          method: 'GET',
          headers: headers,
          mode: 'cors',
          cache: 'default',
        });

        if (response.ok === false) {
          throw new Error(response.statusText);
        }

        const data = await response.json();

        cache.current[url] = data;

        if (cancelRequest) {
          return;
        }

        dispatch({ type: 'FETCHED', payload: data });
      } catch (error) {
        if (cancelRequest) {
          return;
        }

        dispatch({ type: 'FETCH_ERROR', payload: error.message });
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, [ url, token ]);

  return state;
};

export {
  useFetch,
};
