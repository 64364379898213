import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AvatarIcon } from 'icons/avatar.svg';

export function AvatarDisplay({ avatar }) {
  return (
    <div className="avatar">
      {avatar ? (
        <div className="picture">
          <img src={avatar} alt="Avatar" />
        </div>
      ) : (
        <div className="avatar-icon">
          <AvatarIcon />
        </div>
      )}
    </div>
  );
}

AvatarDisplay.propTypes = {
  avatar: PropTypes.string,
};
