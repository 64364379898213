import React, { useEffect } from 'react';
import { deviceClassNames } from 'utils/device';
import { useFetch } from 'hooks/useFetch';
import { useStore } from 'react-simple-observable-store';
import { LoadingScreen } from 'components/LoadingScreen';
import { FormattedMessage } from 'react-intl';

export function LoadConfigs(props) {
  const { status, result, error } = useFetch('/api/configs');
  const [ , setConfigs ] = useStore('configs');

  useEffect(() => {
    setConfigs(result);
  }, [result]);

  if (status === 'fetching' || status === 'idle') {
    return (
      <div className={`App ${deviceClassNames()}`}>
        <LoadingScreen />
      </div>
    );
  }

  if (status === 'error') {
    console.error('LoadConfigs', error);

    return (
      <div className={`App ${deviceClassNames()}`}>
        <span className="message">
          <FormattedMessage id="loading.error" />
        </span>
      </div>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}
