import React, { Component } from 'react';
import localForage from 'localforage';
import { FormattedMessage } from 'react-intl';
import { logError } from 'utils/error';
import { ErrorScreen } from 'components/ErrorScreen';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  componentDidCatch(e, info) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: e,
    });

    // Delete potentially corrupted state
    localForage.clear();

    // Send error log to server
    logError(e);
  }

  render() {
    if (this.state.error) {
      // Error path
      return (
        <ErrorScreen>
          <FormattedMessage id="error.default" />
        </ErrorScreen>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
