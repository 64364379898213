import { useEffect, useState } from 'react';

const useDistance = (enabled = true) => {
  const [ state, setState ] = useState(null);

  useEffect(() => {
    if (enabled) {
      navigator.geolocation.getCurrentPosition((position) => {
        setState(position);
      }, () => {
        setState(null);
      });
    }
  }, []);

  return state;
};

export {
  useDistance,
};
