import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';

export function Btn({ children, className = '', wide = false, rounded = false, type = 'button', variant, ...props }) {
  const classNames = [
    'Btn',
    ...className.split(' '),
    variant && `variant-${variant}`,
    wide && 'wide',
    rounded && 'rounded',
  ].filter(Boolean);

  return (
    <button {...props} className={classNames.join(' ')} type={type}>
      {children}
    </button>
  );
}

Btn.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
  variant: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  rounded: PropTypes.bool,
  wide: PropTypes.bool,
};
