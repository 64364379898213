import './style/index.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';

export function Faq({ open = false }) {
  const intl = useIntl();
  const [ isOpen, setIsOpen ] = useState(open);

  const contents = [
    {
      question: intl.formatMessage({ id: 'experience.faq.question1' }),
      answer: intl.formatMessage({ id: 'experience.faq.answer1' }, {
        ol: (...chunks) => <ol>{chunks}</ol>,
        li: (...chunks) => <li>{chunks}</li>,
      }),
    }, {
      question: intl.formatMessage({ id: 'experience.faq.question2' }),
      answer: intl.formatMessage({ id: 'experience.faq.answer2' }, {
        ol: (...chunks) => <ol>{chunks}</ol>,
        li: (...chunks) => <li>{chunks}</li>,
      }),
    }, {
      question: intl.formatMessage({ id: 'experience.faq.question3' }),
      answer: intl.formatMessage({ id: 'experience.faq.answer3' }, {
        ol: (...chunks) => <ol>{chunks}</ol>,
        li: (...chunks) => <li>{chunks}</li>,
      }),
    }
  ];

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Faq">
      <header onClick={onOpen}>
        <h2>
          <FormattedMessage id="experience.faq" />
        </h2>
        <span className={`icon ${isOpen ? 'open' : ''}`}>
          <AngleDownIcon />
        </span>
      </header>

      {isOpen && (
        <div className="content">
          {contents.map((content, i) => (
            <div className="block" key={i}>
              <h3 className="question">{content.question}</h3>
              <div className="answer">{content.answer}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Faq.propTypes = {
  open: PropTypes.bool,
};
