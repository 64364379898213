import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Logo } from 'components/Logo';

export function Header({ title }) {
  return (
    <div className="Header">
      <div className="wrapper-site">
        <Logo />

        {title && <h1>{title}</h1>}
      </div>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
};
