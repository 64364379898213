import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

export function FieldError({ name }) {
  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <div className="FieldError">{error}</div> : null
      }
    />
  );
}

FieldError.propTypes = {
  name: PropTypes.string.isRequired,
};
