import { useIntl } from 'react-intl';

const useValidators = () => {
  const intl = useIntl();

  const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

  const required = value => value ? undefined : intl.formatMessage({ id: 'field.required' });
  const requiredMultiple = value => value?.length ? undefined : intl.formatMessage({ id: 'field.required' });

  const minLength = min => value =>
    value?.length >= min ? undefined : intl.formatMessage({ id: 'field.minLength' }, { min });

  return {
    composeValidators,
    required,
    requiredMultiple,
    minLength,
  };
};

export {
  useValidators,
};
