import './style/index.scss';

import React from 'react';
import { useStore } from 'react-simple-observable-store';
import { ExperienceItem } from 'components/ExperienceItem';
import { Btn } from 'components/Btn';
import { Chip } from 'components/Chip';
import { useFetch } from 'hooks/useFetch';
import { useScroll } from 'hooks/useScroll';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as ImportIcon } from 'icons/import.svg';
import { LoadingScreen } from 'components/LoadingScreen';
import { ErrorScreen } from 'components/ErrorScreen';
import { useNavigate } from 'react-router-dom';
import { Header } from 'components/Header';
import clsx from 'clsx';
import { Breadcrumb } from 'components/Breadcrumb';
import { ExportExplanation } from 'components/ExportExplanation';
import { usePreviousLocation } from 'hooks/usePreviousLocation';

export function ExperienceList() {
  const [user] = useStore('user');
  const { y } = useScroll();
  const experiences = useFetch('/api/draft_experiences', { token: user.token });
  const navigate = useNavigate();

  usePreviousLocation();

  const experiencesStatus = experiences.status;
  const experiencesResult = experiences.result;
  const experiencesError = experiences.error;

  if (experiencesStatus === 'fetching' || experiencesStatus === 'idle') {
    return (
      <div className={`ExperienceList ${experiencesStatus}`}>
        <LoadingScreen />
      </div>
    );
  }

  if (experiencesStatus === 'error') {
    console.error('ExperienceList', experiencesError);

    return (
      <div className={'ExperienceList error'}>
        <ErrorScreen>
          <FormattedMessage id="loading.error" />
        </ErrorScreen>
      </div>
    );
  }

  const handleAdd = () => {
    navigate('/drafts/create');
  };

  const minified = y > 0;
  const fromDesktop = window.sessionStorage.getItem('from') === 'desktop';

  return (
    <div className={clsx('ExperienceList', experiencesStatus, fromDesktop && 'with-header')}>
      {fromDesktop && (
        <>
          <Header />
          <Breadcrumb title={<FormattedMessage id="experienceList.title" />} />
          <h1><FormattedMessage id="experienceList.title" /></h1>
        </>
      )}

      <div className="list">
        {experiencesResult?.length ? experiencesResult.map(experience => {
          if (experience.status === 'published') {
            return (
              <a href={`${process.env.REACT_APP_WEBSITE}/fr-fr/solo/-${experience.experienceId}`} key={`${experience.id}-${experience.experienceId}`} className="silent-link item">
                <ExperienceItem experience={experience} />
              </a>
            );
          }

          return (
            <Link to={`/drafts/${experience.id}`} key={experience.id} className="silent-link item">
              <ExperienceItem experience={experience} />
            </Link>
          );
        }) : (
          <ExportExplanation onAdd={handleAdd} />
        )}
      </div>

      {experiencesResult?.length > 0 && (
        <Btn className={minified ? 'mini' : undefined} rounded onClick={handleAdd}>
          <span className="icon">
            <ImportIcon />
          </span>

          {!minified && <span className="label">
            <FormattedMessage id="experienceList.add" />
          </span>}

          {!minified && <Chip className="beta">
            <FormattedMessage id="beta" />
          </Chip>}
        </Btn>
      )}
    </div>
  );
}
