export function latlngDistance(latlng1, latlng2) {
  const [ lng1, lat1 ] = !Array.isArray(latlng1) ? latlng1.replace(' ', '').split(',') : latlng1;
  const [ lng2, lat2 ] = !Array.isArray(latlng2) ? latlng2.replace(' ', '').split(',') : latlng2;

  const R = 6371e3;
  const φ1 = lat1 * Math.PI / 180;
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lng2 - lng1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;

  if (isNaN(d)) {
    throw new Error('Wrong coordinate values');
  }

  return d;
}

export function formatDistance(distance) {
  const startDistance = Math.round(distance / 100) / 10;

  return String(startDistance).replace('.', ',');
}

export function distanceFromGeojson(geojson) {
  let distance = 0;

  if (!geojson?.geometry?.coordinates?.length) {
    return distance;
  }

  for (let i = 0, l = geojson.geometry.coordinates.length - 1; i < l; i ++) {
    distance += latlngDistance(geojson.geometry.coordinates[i], geojson.geometry.coordinates[i + 1]);
  }

  return distance;
}

export function elevationFromGeojson(geojson) {
  let positiveElevation = 0;
  let negativeElevation = 0;

  if (!geojson?.geometry?.coordinates?.length) {
    return { positiveElevation, negativeElevation };
  }

  for (let i = 0, l = geojson.geometry.coordinates.length - 1; i < l; i ++) {
    const elevation = geojson.geometry.coordinates[i][2];
    const nextElevation = geojson.geometry.coordinates[i + 1][2];

    if (!elevation || !nextElevation) {
      continue;
    }

    if (elevation > nextElevation) {
      negativeElevation += elevation - nextElevation;
    } else {
      positiveElevation += nextElevation - elevation;
    }
  }

  return { positiveElevation: Math.round(positiveElevation), negativeElevation: Math.round(negativeElevation) };
}

export function durationFromGeojson(geojson) {
  if (!geojson?.properties?.coordinateProperties?.times?.length) {
    return null;
  }

  const startTime = geojson.properties.coordinateProperties.times[0];
  const endTime = geojson.properties.coordinateProperties.times[geojson.properties.coordinateProperties.times.length - 1];

  return Math.round((Number(new Date(endTime)) - Number(new Date(startTime))) / 1000 / 60);
}

export function predictDurationFromGeojson(distance, elevation, sport = 'pedestre') {
  if (sport === 'pedestre') {
    return Math.round((distance + elevation.positiveElevation * 10) / 3500 * 60);
  }

  return Math.round((distance + elevation.positiveElevation * 10) / 15000 * 60);
}

export function flattenFeature(content) {
  if (!content || ! content?.type) {
    return null;
  }

  if (content.type === 'Feature') {
    return flattenGeojson(content);
  }

  const geojsonContainer = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: content?.properties ?? {},
  };

  if (content.type === 'FeatureCollection') {
    if (content.features?.length) {
      geojsonContainer.geometry.coordinates = content.features.reduce((prev, curr) => {
        const geojson = flattenGeojson(curr);

        if (!geojson?.geometry?.coordinates) {
          return [];
        }

        return prev.concat(geojson.geometry.coordinates);
      }, []);
    }
  }

  return geojsonContainer;
}

export function flattenGeojson(geojson) {
  const geojsonContainer = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: [],
    },
    properties: geojson?.properties ?? {},
  };

  if (geojson?.geometry?.type === 'Point') {
    geojsonContainer.geometry.coordinates = [geojson.geometry.coordinates];

    return geojsonContainer;
  }

  if (geojson?.geometry?.type === 'MultiLineString') {
    if (geojsonContainer?.properties?.coordinateProperties?.times?.length) {
      geojsonContainer.properties.coordinateProperties.times = geojsonContainer.properties.coordinateProperties.times.reduce((prev, curr) => prev.concat(curr), []);
    }

    return geojsonContainer;
  }

  return geojson;
}
