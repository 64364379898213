import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { formatDistance } from 'utils/geo';
import { formatDuration } from 'utils/date';

import { ReactComponent as DoubleArrowIcon } from 'icons/double-arrow.svg';
import { ReactComponent as ArrowTopRightIcon } from 'icons/arrow-top-right.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';

export function GeoInfos({
  distance = null,
  positiveElevation = null,
  duration = null,
}) {
  return (
    <div className="GeoInfos">
      {distance !== null && <span className="info">
        <span className="icon double-arrow">
          <DoubleArrowIcon />
        </span>

        {formatDistance(distance)} km
      </span>}

      {positiveElevation !== null && <span className="info">
        <span className="icon arrow-top-right">
          <ArrowTopRightIcon />
        </span>

        {Math.round(positiveElevation)} m
      </span>}

      {duration !== null && <span className="info">
        <span className="icon">
          <ClockIcon />
        </span>

        {formatDuration(duration)}
      </span>}
    </div>
  );
}

GeoInfos.propTypes = {
  distance: PropTypes.number,
  positiveElevation: PropTypes.number,
  duration: PropTypes.number,
};
