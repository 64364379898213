import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-simple-observable-store';
import { FormattedMessage } from 'react-intl';
import { formatDuration } from 'utils/date';
import { formatDistance, latlngDistance } from 'utils/geo';
import { useDistance } from 'hooks/useDistance';
import { Contributor } from 'components/Contributor';
import { Btn } from 'components/Btn';
import { Map } from 'components/Map';
import { Diaporama } from 'components/Diaporama';
import { ReactComponent as DoubleArrowIcon } from 'icons/double-arrow.svg';
import { ReactComponent as ArrowTopRightIcon } from 'icons/arrow-top-right.svg';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import { ReactComponent as BinIcon } from 'icons/bin.svg';
import { ReactComponent as PedestrianIcon } from 'icons/pedestrian.svg';
import { ReactComponent as BikeIcon } from 'icons/bike.svg';
import { ReactComponent as BeginnerIcon } from 'icons/beginner.svg';
import { ReactComponent as GoodIcon } from 'icons/good.svg';
import { ReactComponent as ExpertIcon } from 'icons/expert.svg';
import { ReactComponent as QuoteIcon } from 'icons/quote.svg';
import round from 'lodash/round';

export function ExperiencePreview({ experience, onDelete, onCancel }) {
  const [user] = useStore('user');
  const [configs] = useStore('configs');
  const position = useDistance(experience.status === 'published');

  const startPoint = experience.geojson?.geometry?.coordinates?.[0];

  const startDistance = position && startPoint ? formatDistance(latlngDistance(
    [ position.coords.latitude, position.coords.longitude ],
    [ startPoint[1], startPoint[0] ]
  )) : null;

  const difficulty = configs.types.find(el => el.type === 'difficulties' && el.code === experience.difficulty)?.label;
  const sport = configs.types.find(el => el.type === 'sports' && el.code === experience.sport)?.label;

  let difficultyIcon = <ExpertIcon />;
  switch (experience.difficulty) {
    case 'beginner':
      difficultyIcon = <BeginnerIcon />;
      break;

    case 'good':
      difficultyIcon = <GoodIcon />;
      break;

    default:
      break;
  }

  const Actions = () => experience.status === 'moderation' ? (
    <Btn className="back" variant={1} onClick={onCancel} wide>
      <span className="label">
        <FormattedMessage id="backToList" />
      </span>
    </Btn>
  ) : (
    <div className="actions">
      <Btn variant={1} onClick={onCancel}>
        <span className="label">
          <FormattedMessage id="back" />
        </span>
      </Btn>

      <Btn type="submit" onClick={onDelete}>
        <span className="label">
          <FormattedMessage id="experience.remove" />
        </span>
      </Btn>
    </div>
  );

  return (
    <div className="ExperiencePreview">
      <section className="page-content">
        <h2>
          <span className="label">
            {experience.status === 'moderation' ? (
              <span className="label">
                <FormattedMessage id="onlineSoon" />
              </span>
            ) : (
              <span className="label">
                <FormattedMessage id="experience.rejected" />
              </span>
            )}
          </span>
        </h2>

        <div className="explanation">
          {experience.status === 'moderation' ? (
            <FormattedMessage id="experience.moderation.explanation" />
          ) : (
            <>
              <FormattedMessage id="experience.rejected.description" values={{
                email: <a href="mailto:support@decathlon-outdoor.com">support@decathlon-outdoor.com</a>,
              }} />
              <br /><br />
              <FormattedMessage id={`experience.moderation.rejectedReason.${experience.rejectedReason?.replace(' ', '_')}`} />
            </>
          )}
        </div>

        <Actions />
      </section>

      <section className="page-content wide collapsed flexbox-size-fixer">
        <Diaporama photos={experience.photos ?? []} />
      </section>

      <section className="page-content">
        <h1>{experience.name}</h1>

        <div className="memorable">
          <div className="icon quote">
            <QuoteIcon />
          </div>
          <span>{experience.memorable}</span>
        </div>

        <Contributor user={user} variant={1} />
      </section>

      <section className="page-content">
        <div className="infos">
          <span className="info">
            <span className="icon sport">
              {experience.sport === 'pedestre' ? <PedestrianIcon /> : <BikeIcon />}
            </span>

            {sport}
          </span>
          <span className="info">
            <span className="icon difficulty">
              {difficultyIcon}
            </span>

            {difficulty}
          </span>
          <span className="info">
            <span className="icon">
              <ClockIcon />
            </span>

            {formatDuration(experience.duration)}
          </span>
          <span className="info">
            <span className="icon double-arrow">
              <DoubleArrowIcon />
            </span>

            {formatDistance(experience.distance)} km
          </span>
          <span className="info">
            <span className="icon">
              <ArrowTopRightIcon />
            </span>

            {experience.positiveElevation} m
          </span>
        </div>
      </section>

      <section className="page-content">
        <h2><FormattedMessage id="experience.details" /></h2>

        <Map geojson={experience.geojson} />
      </section>

      <section className="page-content">
        <h2><FormattedMessage id="experience.field.description.label" /></h2>

        <div className="description">{experience.description}</div>
      </section>

      {Boolean(startPoint) && <section className="page-content">
        {startDistance ? (
          <h2><FormattedMessage id="startAt" values={{ distance: startDistance }} /></h2>
        ) : (
          <h2><FormattedMessage id="startPoint" /></h2>
        )}

        <Map point={startPoint} />

        <div className="startPoint">
          <div>{`${round(startPoint[1], 6)}, ${round(startPoint[0], 6)}`}</div>
        </div>
      </section>}

      <section className="page-content">
        <Contributor user={user} />
      </section>

      {experience.status === 'moderation' && (
        <section className="page-content alt">
          <Btn className="remove-link" variant={4} wide onClick={onDelete} disabled>
            <span className="icon">
              <BinIcon />
            </span>

            <FormattedMessage id="experience.remove" />
          </Btn>
        </section>
      )}

      <section className="page-content">
        <Actions />
      </section>
    </div>
  );
}

ExperiencePreview.propTypes = {
  experience: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
};
