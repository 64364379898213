import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Btn } from 'components/Btn';
import { FormattedMessage } from 'react-intl';
import { Loader } from 'components/Loader';

export function ExperienceActions({ title, description, cancelLabel, submitLabel, submitting = false, disabled = false, onCancel }) {
  const classNames = [
    'ExperienceActions',
    submitting && 'submitting',
    disabled && 'disabled',
  ].filter(Boolean);

  return (
    <div className={classNames.join(' ')}>
      {title && <h2>{title}</h2>}

      {description && <div className="description">{description}</div>}

      <div className="actions">
        <Btn variant={1} onClick={onCancel} disabled={submitting}>
          <span className="label">
            {cancelLabel ? cancelLabel : <FormattedMessage id="later" />}
          </span>
        </Btn>

        <Btn type="submit" disabled={disabled || submitting}>
          {submitting ? (
            <span className="icon">
              <Loader variant={1} />
            </span>
          ) : (
            <span className="label">
              {submitLabel ? submitLabel : <FormattedMessage id="submit" />}
            </span>
          )}
        </Btn>
      </div>
    </div>
  );
}

ExperienceActions.propTypes = {
  title: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  description: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
};
