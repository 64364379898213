import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldError } from 'components/FieldError';
import { ReactComponent as AngleDownIcon } from 'icons/angle-down.svg';

export function FieldSelect({ input, meta, example, label, required, ...props }) {
  const lastNameSegment = input.name.split('.');

  const classNames = [
    lastNameSegment[lastNameSegment.length - 1],
    meta.touched && meta.error && 'error',
    input.value && 'with-value',
    props.disabled && 'disabled',
  ].filter(Boolean);

  return (
    <div className={`Field FieldSelect ${classNames.join(' ')}`}>
      <label>
        <div className="label">
          {label}
        </div>

        <div className="container">
          <select
            {...input}
            {...props}
          >
            {props.placeholder && <option className="placeholder" value="">{props.placeholder}</option>}
            {props.children}
          </select>
          <AngleDownIcon />
        </div>

        <div className="field-footer">
          <FieldError name={input.name} />

          <div className="extra">
            {!required && (
              <div className="facultative"><FormattedMessage id="field.facultative" /></div>
            )}
          </div>
        </div>

        {example && (
          <div className="example">{example}</div>
        )}
      </label>
    </div>
  );
}

FieldSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  example: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
