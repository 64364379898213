import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Btn } from 'components/Btn';
import { Chip } from 'components/Chip';
import { ReactComponent as ImportIcon } from 'icons/import.svg';

export function ExportExplanation({ onAdd }) {
  return (
    <div className="ExportExplanation">
      <img src={process.env.PUBLIC_URL + '/images/explanation.png'} alt="explanation" />

      <div className="title">
        <FormattedMessage id="experienceList.explanation" />
        <br />
        <FormattedMessage id="experienceList.explanation2" />
      </div>

      <ul>
        <li><FormattedMessage id="experienceList.explanation.step1" /></li>
        <li><FormattedMessage id="experienceList.explanation.step2" /></li>
        <li><FormattedMessage id="experienceList.explanation.step3" /></li>
      </ul>

      <Btn rounded onClick={onAdd}>
        <span className="icon">
          <ImportIcon />
        </span>

        <span className="label">
          <FormattedMessage id="experienceList.add" />
        </span>

        <Chip className="beta">
          <FormattedMessage id="beta" />
        </Chip>
      </Btn>
    </div>
  );
}

ExportExplanation.propTypes = {
  onAdd: PropTypes.func,
};
