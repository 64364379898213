const didomiConfig = {
  'app': {
    'name': 'Decathlon',
    'logoUrl': 'https://static.decathlon-outdoor.com/logo_decathlon_outdoor.png',
    'vendors': {
      'iab': {
        'all': false,
        'stacks': {
          'ids': null,
          'auto': true
        },
        'enabled': false,
        'exclude': [],
        'include': [],
        'version': 2,
        'restrictions': []
      },
      'custom': [
        {
          'id': 'amplitude-TWph43f6',
          'name': 'Amplitude',
          'policyUrl': 'https://amplitude.com/privacy',
          'purposeIds': ['lescookie-KMVHiPpq'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'facebooka-QyMDLGJQ',
          'name': 'Facebook Advertising',
          'policyUrl': 'https://www.facebook.com/full_data_use_policy',
          'purposeIds': ['lescookie-KMVHiPpq'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'googleads-Ak32TPDA',
          'name': 'Google Ads',
          'policyUrl': 'https://policies.google.com/privacy',
          'purposeIds': ['lescookie-KMVHiPpq'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'cookiesan-aY7WekKr',
          'name': 'Google Analytics',
          'policyUrl': 'https://marketingplatform.google.com/about/analytics/terms/us/',
          'purposeIds': ['lescookie-DMGDhexy'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'hotjar-jeyiFPgE',
          'name': 'Hotjar',
          'policyUrl': 'https://www.hotjar.com/legal/policies/privacy/',
          'purposeIds': ['lescookie-DMGDhexy'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'taboola-GhieyVn4',
          'name': 'Taboola',
          'policyUrl': 'https://www.taboola.com/fr/policies/privacy-policy-fr',
          'purposeIds': ['lescookie-KMVHiPpq'],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        },
        {
          'id': 'decathlon-fpUWqZwR',
          'name': 'Decathlon - Nécessaires',
          'policyUrl': 'https://www.decathlon.fr/landing/donnees-personnelles-decathlon/_/R-a-donnees-personnelles',
          'purposeIds': [],
          'legIntPurposeIds': [],
          'usesNonCookieAccess': false
        }
      ],
      'google': {
        'fullATP': false
      }
    },
    'privacyPolicyURL': 'https://www.decathlon-outdoor.com/fr-fr/legal',
    'essentialPurposes': ['lescookie-DMGDhexy'],
    'gdprAppliesGlobally': true,
    'gdprAppliesWhenUnknown': false,
    'apiKey': '929521d8-0c53-458b-9f07-852d7d00d609',
    'customPurposes': [
      {
        'id': 'lescookie-KMVHiPpq',
        'name': {
          'de': 'Marketing-Cookies',
          'en': 'Marketing Usage',
          'es': 'Cookies publicitarias',
          'fr': 'Publicités personnalisées',
          'hu': 'Marketing célú sütik',
          'it': 'COOKIES DI PROFILAZIONE - di terza parte',
          'nl': 'Marketing Cookies',
          'pt': 'Marketing',
          'ro': 'Cookie de Publicitate',
          'sv': 'Reklamcookies',
          'tr': 'Pazarlama Amaçlı'
        },
        'description': {
          'de': 'Diese Cookies und ähnliche Technologien werden eingesetzt, um personalisierte und dadurch mögliche relevante werbliche Inhalte anzeigen zu können. Marketing-Cookies werden eingesetzt, um interessante Werbeinhalte anzuzeigen und die Wirksamkeit von Kampagnen zu messen. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich. Marketing- und Retargeting-Cookies helfen uns mögliche relevanten Werbeinhalte für dich anzuzeigen.',
          'en': 'These cookies track your online activity to help advertisers deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and almost always of third-party provenance.',
          'es': 'Permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el edito haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.  Adicionalmente,  permiten la eleboración de perfiles de navegación de los usuarios de los sitios, aplicaciones y plataformas, a los que están vinculadas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.',
          'fr': 'Ces cookies nous permettent de vous proposer des publicités personnalisées. Ils nous aident également à mesurer la performance de nos publicités et à vous envoyer uniquement les plus pertinentes.',
          'hu': 'Ezen sütik arra szolgálnak, hogy az aktivitásod alapján számodra még relevánsabb és érdekesebb tartalmat tudjunk megjeleníteni. Ezek állandó sütik, és szinte mindig harmadik féltől származnak.',
          'it': 'Permettono di seguire e tracciare, mediante strumenti automatizzati, il comportamento dell’utente durante la sua navigazione in internet. Hanno lo scopo di creare profili relativi all’utente e vengono utilizzati dalla terza parte al fine di inviare all’utente contenuti pubblicitari personalizzati in linea con le sue preferenze e i suoi interessi, manifestati nell’ambito della sua navigazione in rete.',
          'nl': 'We gebruiken ook cookies voor marketingdoeleinden. Zo kunnen we je aanbiedingen sturen waar je ook écht op zit te wachten. Deze cookies volgen surfgedrag. Ze zien zien welke pagina\'s je bekijkt en hoe je onze webshop of andere diensten gebruikt. Zo kunnen we content op je afstemmen en gepersonaliseerde aanbiedingen doen. Met deze cookies kunnen wij je relevante advertenties tonen op onze (partner)sites. Wij kunnen bijhouden hoe vaak je een advertentie al hebt gezien, of je op advertenties klikt en of je bestellingen plaatst nadat je een advertentie hebt gezien of hierop hebt geklikt.\n\nWij geven derde partijen ook de mogelijkheid om advertentiecookies te plaatsen op onze websites. Wij plaatsen zelf ook advertentiecookies op websites van anderen. En die info kunnen we ook combineren. Dat doen we om jouw ervaring en aanbiedingen bij Decathlon (online) persoonlijker te maken, afgestemd op jouw interesses.',
          'pt': 'Estes cookies rastreiam a sua actividade online para ajudar os anunciantes a fornecer publicidade mais relevante ou para limitar o número de vezes que vê um anúncio. Estes cookies podem partilhar essa informação com outras organizações ou anunciantes. Estes são cookies persistentes e quase sempre de proveniência de terceiros.',
          'ro': 'Cookie-urile de publicitate ne permit să-ți furnizăm publicitate personalizată pe website-ul Decathlon și pe\nalte website-uri externe, precum și să analizăm eficiența campaniilor de marketing. Atât noi cât și\npartenerii noștri colectăm informații despre activitatea ta pe website-ul Decathlon, astfel încât să-ți\nidentificăm interesele și preferințele și să-ți furnizăm publicitate personalizată.\n\nCookie-urile de publicitate ne permit atât nouă, cât și partenerilor noștri, să analizăm pentru ce produse de\npe website-ul nostru ai manifestat interes, astfel încât, la o vizită ulterioară pe un website extern, să\nafișăm conținut publicitar în funcție de produsul sau tipurile de produse pentru care ai manifestat interes\npe website-ul Decathlon. Dacă refuzi plasarea acestui tip de cookie-uri, este posibil să ți se afișeze în\ncontinuare conținut publicitar cu produsele și serviciile Decathlon, dar acestea nu vor mai fi la fel de\nrelevante pentru tine. De asemenea, vom utiliza aceste cookie-uri pentru a limita nr. de afișări al unei\nanumite reclame pe website-ul nostru sau pe un website extern.',
          'sv': 'Vi använder cookies för marknadsföringsändamål. På så sätt kan vi skicka erbjudanden som du verkligen väntar på. Dessa cookies spårar surfbeteende. De ser vilka sidor du visar och hur du använder vår webbshop eller andra tjänster. På så sätt kan vi skräddarsy innehåll för dig och göra personliga erbjudanden. Med dessa cookies kan vi visa relevanta annonser på våra (partner) webbplatser. Vi kan hålla reda på hur ofta du redan har sett en annons, om du klickar på annonser och om du gör beställningar efter att du har sett eller klickat på en annons. Vi tillåter också tredje part att placera reklamcookies på våra webbplatser. Vi placerar också reklamcookies på andra webbplatser. Och vi kan också kombinera den informationen. Vi gör detta för att göra din upplevelse och erbjudanden på Decathlon (online) mer personlig, skräddarsydd för dina intressen.',
          'tr': 'Bu çerezler, daha uygun reklamlar sunmasına yardımcı olmak veya bir reklamı kaç kez gördüğünüzü belirlemek için çevrimiçi etkinliğinizi izler. Bu çerezler, verileri diğer kuruluşlar veya reklam verenlerle paylaşabilir. Bunlar kalıcı çerezler olmakla birlikte neredeyse her zaman üçüncü taraf kaynaklıdır.'
        },
        'namespace': 'custom'
      },
      {
        'id': 'lescookie-DMGDhexy',
        'name': {
          'de': 'Analyse',
          'en': 'Analytics Usage',
          'es': 'Cookies de análisis',
          'fr': 'Mesure d\'audience et de performance des contenus',
          'hu': 'Analitikai, statisztikai sütik',
          'it': 'COOKIES TECNICI - analityics anonimizzati',
          'nl': 'Analytische Cookies',
          'pl': 'Măsurarea audienței și a performanțelor conținutului',
          'pt': 'Analítica',
          'ro': 'Măsurarea audienței și a performanțelor conținutului',
          'sv': 'Statistikcookies',
          'tr': 'Analiz Amaçlı'
        },
        'description': {
          'de': 'Diese Cookies helfen, das Nutzungsverhalten besser zu verstehen, zu analysieren und die Funktion der Seite zu verbessern. Analyse-Cookies ermöglichen die Erhebung von Nutzungs- und Erkennungsmöglichkeiten durch Erst- oder Drittanbieter, in so genannten pseudonymen Nutzungsprofilen. Ein unmittelbarer Rückschluss auf eine Person ist dabei nicht möglich.',
          'en': 'These cookies collect information about how you use a website, like which pages you visited and which links you clicked on. None of this information can be used to identify you. It is all aggregated and, therefore, anonymized. Their sole purpose is to improve website functions. This includes cookies from third-party analytics services as long as the cookies are for the exclusive use of the owner of the website visited.',
          'es': 'Permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.',
          'fr': 'Ces cookies nous aident à améliorer les fonctionnalités et le contenu de notre site. Pour ça, nous collectons et analysons les données relatives au nombre de visiteurs, aux pages visitées, à la durée de visite, aux sources du trafic.',
          'hu': 'Ezen sütik arra szolgálnak, hogy jobban megértsük és megismerjük a weboldal azon elemeit és pontjait amelyek nem optimálisan funkcionálnak, ezzel lehtőséget teremtve a weboldal fejlesztésére.',
          'it': 'Permettono di raccogliere informazioni, in forma aggregata e quindi non riconducibile al singolo, relative al numero di utenti alle pagine più visitate, ai luoghi da cui ci si collega, etc. per elaborare statistiche sul servizio e sull’utilizzo del sito. Tali cookies possono anche essere di terze parti. In questo caso però il Titolare li deve aver resi anonimi (es. mediante anonimizzazione dell’IP o mascheramento di parte di esso) e non deve consentire alla terza parte di incrociare le informazioni raccolte attraverso il sito con altre già a sua disposizione o di trasmetterli a terzi.\n',
          'nl': 'Meten is weten. Met analytische cookies meten we het gebruik van onze websites. Jouw gebruik van onze site wordt in onze systemen geregistreerd onder een unieke (maar anonieme) ID, waardoor we je herkennen als nieuwe of terugkerende bezoeker. Met cookies kijken we naar de effectiviteit van de website en de getoonde advertenties; we kijken niet naar individueel surfgedrag. Aan de hand van die informatie proberen we de website steeds weer een beetje beter te maken.',
          'pl': 'Cookie-uri de analiză: ne ajută să analizăm și să înțelegem cum este utilizat website-ul nostru. Aceste cookie-uri ne permit să îmbunătățim performanța website-ului și să dezvoltăm noi funcționalități, pentru o experiență sportivă virtuală mai plăcută pentru tine și pentru ceilalți vizitatori. Printre altele, aceste cookie-uri ne permit să contorizăm vizitele pe wesbite, să analizăm ce pagini vizitează cel mai des utilizatorii și cât timp petrec utilizatorii pe o anumită pagină și să vizualizăm eficiența campaniilor de publicitate de pe website-ul nostru. De exemplu, Decathlon utilizează Google Analytics pentru a urmări relevanța reclamelor și pentru a îmbunătăți și optimiza personalizarea acestora. Deciziile luate în urma prelucrării acestor informații pot fi bazate exclusiv pe prelucrarea automată.',
          'pt': 'O objectivo é compreender melhor o comportamento de uso geral. A recolha de possibilidades de utilização e reconhecimento por fornecedores de primeira ou terceira parte em perfis de utilização pseudónimos é tornada possível.',
          'ro': 'Cookie-uri de analiză: ne ajută să analizăm și să înțelegem cum este utilizat website-ul nostru. Aceste cookie-uri ne permit să îmbunătățim performanța website-ului și să dezvoltăm noi funcționalități, pentru o experiență sportivă virtuală mai plăcută pentru tine și pentru ceilalți vizitatori. Printre altele, aceste cookie-uri ne permit să contorizăm vizitele pe wesbite, să analizăm ce pagini vizitează cel mai des utilizatorii și cât timp petrec utilizatorii pe o anumită pagină și să vizualizăm eficiența campaniilor de publicitate de pe website-ul nostru. De exemplu, Decathlon utilizează Google Analytics pentru a urmări relevanța reclamelor și pentru a îmbunătăți și optimiza personalizarea acestora. Deciziile luate în urma prelucrării acestor informații pot fi bazate exclusiv pe prelucrarea automată.\n',
          'sv': 'Att mäta är att veta. Vi mäter användningen av våra webbplatser med analytiska kakor. Din användning av vår webbplats registreras i våra system under ett unikt (men anonymt) ID, genom vilket vi känner igen dig som en ny eller återkommande besökare. Med cookies tittar vi på webbplatsens effektivitet och de annonser som visas; vi tittar inte på individuellt surfbeteende. Vi försöker göra webbplatsen lite bättre utifrån den informationen.',
          'tr': 'Bu çerezler, tıkladığınız sayfalar ve bağlantılar olmak üzere, bir web sitesini nasıl kullandığınız hakkında veri toplar. Bu verilerin hiçbiri kimliğinizi belirlemek için kullanılamaz. Tüm veriler anonimleştirilmiştir. Tek amaçları web sitesi işlevlerini iyileştirmektir. Çerezler ziyaret edilen web sitesi sahibinin özel kullanımı için olduğu sürece, üçüncü taraf analitik hizmetlerinden gelen çerezler de kullanılır.'
        },
        'namespace': 'custom'
      }
    ],
    'deploymentId': 'MPfTpMAc',
    'consentDuration': 31622400
  },
  'user': {
    'bots': {
      'consentRequired': false
    },
    'ignoreConsentBefore': '2021-01-28T14:00:00.000Z'
  },
  'theme': {
    'css': '.didomi-popup-container{\n  border:0px solid black!important;\n}\n\n.didomi-notice-view-partners-link{\n    display: none!important;\n}\n#didomi-notice-learn-more-button, #didomi-consent-popup .didomi-consent-popup-preferences .didomi-button-standard{\n    background-color:white!important;\n    border:2px solid black!important;\n    border-radius:2px!important;\n    font-size:15px!important;\n}\n\n#didomi-host {\n    /*font-family:\'Roboto-Condensed,Arial Narrow,sans-serif\'!important;*/\n}\n\n#didomi-notice-agree-button, #didomi-consent-popup .didomi-consent-popup-preferences .didomi-button-highlight{\n    background-color:#007DBC!important;\n    border:2px solid transparent!important;\n    border-radius:2px!important;\n    font-size:15px!important;\n}\n\n#didomi-host .didomi-exterior-border {\n    border:0px solid transparent!important;\n}\n\n#didomi-host .didomi-popup-backdrop {\n    background-color: rgba(0, 0, 0, 0.8)!important;\n}\n\n.didomi-consent-popup-data-processing__purpose_actions_title{\n    display: none!important;\n}\n\n.didomi-popup-notice-text span{\n    font-size:15px!important;\n}\n\n.didomi-continue-without-agreeing{\n    font-size:15px!important;\n}\n\n@media screen and (max-height:600px){\n    .didomi-popup-notice-logo-container{\n        display: none!important;\n    }\n}',
    'font': 'Arial, sans-serif',
    'color': '#0082c3',
    'linkColor': '#0082c3'
  },
  'notice': {
    'content': {
      'popup': {
        'fr': 'Avec nos partenaires, nous utilisons des cookies et des technologies similaires. Ces cookies sont utiles pour améliorer votre expérience et optimiser les performances de l’application, résoudre les erreurs rencontrées, proposer de nouvelles fonctionnalités, mesurer les performances des contenus et les données statistiques d’audience. Ils nous aident aussi à vous proposer du contenu qui matche avec vos sports préférés.<br/><br/>\nLes données confiées sont traitées par DECATHLON France SE, 4 boulevard de Mons à Villeneuve d\'Ascq, 59650.\n'
      }
    },
    'position': 'popup',
    'closeOnClick': false,
    'closeOnScroll': false,
    'showDataProcessing': false,
    'closeOnScrollThreshold': 0,
    'daysBeforeShowingAgain': 30,
    'closeOnScrollThresholdType': '',
    'closeOnClickNavigationDelay': 0,
    'type': 'optin',
    'denyAsPrimary': true,
    'denyAppliesToLI': false,
    'denyAsLink': true
  },
  'languages': {
    'default': 'fr',
    'enabled': ['fr']
  },
  'tagManager': {
    'provider': 'gtm'
  },
  'preferences': {
    'content': {
      'text': {
        'fr': 'Nos partenaires et nous déposons des cookies et utilisons des informations non sensibles de votre appareil pour améliorer nos produits et afficher des publicités et contenus personnalisés. Vous pouvez accepter ou refuser ces différentes opérations. Cliquez sur "en savoir plus" pour avoir de plus amples informations.<br/>\nLes données confiées sont traitées par DECATHLON France SE, 4 boulevard de Mons à Villeneuve d\'Ascq, 59650.\n'
      },
      'title': {},
      'textVendors': {
        'fr': 'Vous pouvez définir vos préférences de consentement pour chaque partenaire listé ci-dessous individuellement. Cliquez sur le nom d\'un partenaire pour obtenir plus d\'informations sur ce qu\'il fait, les données qu\'il récolte et comment il les utilise.'
      }
    },
    'categories': [
      {
        'type': 'purpose',
        'purposeId': 'lescookie-KMVHiPpq'
      },
      {
        'type': 'purpose',
        'purposeId': 'lescookie-DMGDhexy'
      }
    ],
    'showWhenConsentIsMissing': false,
    'denyAppliesToLI': true
  },
  'regulations': {},
  'integrations': {
    'vendors': {
      'google': {
        'enable': false,
        'refresh': true,
        'eprivacy': false,
        'passTargetingVariables': false
      },
      'salesforce-dmp': {
        'enable': false,
        'namespace': null
      },
      'refreshOnConsent': false
    }
  }
};

export {
  didomiConfig,
};
