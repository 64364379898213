import EXIF from 'exif-js';
import heic2any from 'heic2any';

import { getExtension } from 'utils/uri';

// toBlob polyfill for Safari
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function(callback, type, quality) {
      const binStr = atob(this.toDataURL(type, quality).split(',')[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || 'image/png' }));
    }
  });
}

function convertDMSToDegrees(exifCoord, hemi) {
  const degrees = exifCoord?.length > 0 ? exifCoord[0] : 0;
  const minutes = exifCoord?.length > 1 ? exifCoord[1] : 0;
  const seconds = exifCoord?.length > 2 ? exifCoord[2] : 0;

  // TODO test in south hemisphere
  const flip = hemi === 'W' || hemi === 'S' ? -1 : 1;

  return flip * (degrees + minutes / 60 + seconds / 3600);
}

export function	extractCoordinatesFromFile(file) {
  return new Promise((resolve, reject) => {
    EXIF.getData(file, function() {
      if (this.exifdata?.GPSLatitude !== undefined && this.exifdata?.GPSLongitude !== undefined) {
        return resolve({
          lat: convertDMSToDegrees(this.exifdata?.GPSLatitude, this.exifdata?.GPSLatitudeRef),
          lng: convertDMSToDegrees(this.exifdata?.GPSLongitude, this.exifdata?.GPSLongitudeRef),
        });
      }

      return resolve(null);
    });
  });
}

export const copyExif = async(src, dest) => {
  const exif = await retrieveExif(src);

  return new Blob([ dest.slice(0, 2), exif, dest.slice(2) ], {
    type: 'image/jpeg'
  });
};

const SOS = 0xffda,
  APP1 = 0xffe1,
  EXIFMARKER = 0x45786966;

export const retrieveExif = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', ({ target: { result: buffer } }) => {
      const view = new DataView(buffer);
      let offset = 0;

      if (view.getUint16(offset) !== 0xffd8) {
        return reject(new Error('not a valid jpeg'));
      }

      offset += 2;

      while (true) {
        const marker = view.getUint16(offset);
        if (marker === SOS) {
          break;
        }

        const size = view.getUint16(offset + 2);

        if (marker === APP1 && view.getUint32(offset + 4) === EXIFMARKER) {
          return resolve(blob.slice(offset, offset + 2 + size));
        }

        offset += 2 + size;
      }

      return resolve(new Blob());
    });

    reader.readAsArrayBuffer(blob);
  });

export async function resizeImage(data, quality = 0.8) {
  const ext = getExtension(data.file.name);

  if (ext === 'heif') {
    const blob = await heic2any({
      blob: data.file,
      toType: 'image/jpeg',
    });

    return new Promise((resolve, reject) => {
      const blobURL = URL.createObjectURL(blob);
      const img = new Image();

      img.onload = e => {
        const canvas = document.createElement('canvas');

        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);

        canvas.toBlob(async file => {
          file.preview = blobURL;

          try {
            file = await copyExif(data.file, file);
          } catch (e) {}

          resolve({
            ...data,
            file: blob,
            url: URL.createObjectURL(file),
          });
        }, 'image/jpeg', quality);
      };

      img.onerror = reject;

      img.src = blobURL;
    });
  }

  return new Promise((resolve, reject) => {
    const blobURL = URL.createObjectURL(data.file);

    const img = new Image();

    img.onload = e => {
      const canvas = document.createElement('canvas');

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);

      canvas.toBlob(async file => {
        file.preview = blobURL;

        try {
          file = await copyExif(data.file, file);
        } catch (e) {}

        resolve({
          ...data,
          file,
          url: URL.createObjectURL(file),
        });
      }, data.file.type, quality);
    };

    img.onerror = reject;

    img.src = blobURL;
  });

}
