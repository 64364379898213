import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AvatarDisplay } from './AvatarDisplay';

export function Variant1({ user, classNames }) {
  const { firstName, avatar } = user;

  return (
    <div className={classNames.join(' ')}>
      <header>
        <AvatarDisplay avatar={avatar} />

        <div className="name">
          <FormattedMessage id="createdBy" values={{ name: null }} />
          <b>{firstName}</b>
        </div>
      </header>
    </div>
  );
}

Variant1.propTypes = {
  user: PropTypes.object.isRequired,
  classNames: PropTypes.array,
};
