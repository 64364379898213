import get from './get';
import post from './post';
import put from './put';
import del from './del';

export const defaultParams = {
  headers: new Headers(),
  mode: 'cors',
  cache: 'default',
};

export {
  get,
  post,
  put,
  del,
};

export async function checkStatus(result) {
  const { response, content } = result;

  if (response?.ok === true) {
    return content;
  }

  const error = {
    status: response.status,
    statusText: response.statusText,
    content: content,
  };

  throw error;
}

export async function parse(response) {
  const contentType = response.headers.get('Content-Type') ?? '';

  if (!contentType || contentType.indexOf('application/json') >= 0) {
    let content;

    try {
      content = await response.json();
    } catch (e) {
      content = null;
    }

    return Promise.resolve({ response, content });
  }

  if (contentType && contentType.indexOf('text/html') >= 0) {
    const content = await response.text();
    return Promise.resolve({ response, content });
  }

  const content = await response.blob();

  return Promise.resolve({ response, content });
}

export async function objectToFormData(formValues) {
  const formData = new FormData();

  for (const name in formValues) {
    if (formValues.hasOwnProperty(name)) {
      const value = formValues[name] instanceof File ?
        new Blob([new Uint8Array(await formValues[name].arrayBuffer())], { type: formValues[name].type }) :
        formValues[name]
      ;

      formData.append(name, value);
    }
  }

  return formData;
}

export function addStorageParams(params) {
  if (params.token) {
    params.headers.set('Authorization', `Bearer ${params.token}`);

    delete params.token;
  } else {
    const token = window.localStorage.getItem('decathlonOutdoor.token');

    if (token) {
      params.headers.set('Authorization', `Bearer ${token}`);
    }
  }

  return params;
}

export async function imageExists(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = resolve;
    img.onerror = reject;

    img.src = src;
  });
}

export function cleanData(data) {
  const cleanedData = {};

  for (const i in data) {
    if (data[i] !== undefined) {
      cleanedData[i] = data[i];
    }
  }

  return cleanedData;
}
