import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { FormattedMessage } from 'react-intl';
import { Btn } from 'components/Btn';

export function PopupDelete({ open = false, title, label, action, onConfirm, onChangeState }) {
  return (
    <Popup
      className="PopupDelete"
      open={open}
      onClose={() => onChangeState(false)}
      position="right center"
      closeOnDocumentClick
      role="dialog"
    >
      {close => <>

        <div className="inner-content">
          <h2>{title}</h2>

          <p>{label}</p>
        </div>

        <div className="actions">
          <Btn className="cancel" wide onClick={close}>
            <span className="label">
              <FormattedMessage id="cancel" />
            </span>
          </Btn>

          <Btn className="confirm" variant={1} wide onClick={onConfirm}>
            <span className="label">
              {action}
            </span>
          </Btn>
        </div>
      </>}
    </Popup>
  );
}

PopupDelete.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
  action: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeState: PropTypes.func,
};
