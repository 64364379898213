import './style/index.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-simple-observable-store';
import { useFetch } from 'hooks/useFetch';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { ExperienceForm } from 'components/ExperienceForm';
import { ExperiencePreview } from 'components/ExperiencePreview';
import { LoadingScreen } from 'components/LoadingScreen';
import localforage from 'localforage';
import throttle from 'lodash/throttle';
import upperFirst from 'lodash/upperFirst';
import { del, get, post, put } from 'utils/query';
import { useAnalytics } from 'hooks/useAnalytics';
import { PopupDelete } from 'components/PopupDelete';
import { redirect } from 'utils/device';
import { displayError } from 'utils/error';
import { ErrorScreen } from 'components/ErrorScreen';
import { useConnection } from 'hooks/useConnection';
import { predictDurationFromGeojson } from 'utils/geo';
import { useIsMounted } from 'hooks/useIsMounted';
import { usePreviousLocation } from 'hooks/usePreviousLocation';

export function Experience({ type = 'draft' }) {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  useConnection(type === 'draft');
  const [ user, setUser ] = useStore('user');
  const [ submitting, setSubmitting ] = useState(false);
  const [ isPopupDeleteOpen, setIsPopupDeleteOpen ] = useState(false);
  const [ experience, setExperience ] = useState({});
  const { pushAnalytics } = useAnalytics();
  const pageTime = Number(new Date());

  const route = type === 'draft' ? 'draft_experiences' : 'experiences';

  usePreviousLocation();

  const { status, result, error } = useFetch(`/api/${route}/${id}`, { token: user.token, backupKey: `experience.${id}` });

  useEffect(async() => {
    if (!result) {
      return;
    }

    const localExperience = await localforage.getItem(`experience.${id}`);

    let data;

    if (type === 'draft') {
      data = {
        ...result,
        ...localExperience,
      };
    } else {
      data = {
        ...result,
        status: 'published',
      };
    }

    setExperience(data);

  }, [result]);

  if (status === 'fetching' || status === 'idle') {
    return (
      <div className={`Experience ${status}`}>
        <LoadingScreen />
      </div>
    );
  }

  if (status === 'error') {
    console.error('Experience', error);

    return (
      <div className={`Experience ${status}`}>
        <ErrorScreen>
          <FormattedMessage id="loading.error" />
        </ErrorScreen>
      </div>
    );
  }

  const actionRedirect = (action = 'success') => {
    if (action === 'back' || action === 'delete') {
      return redirect(navigate);
    }

    if (action === 'success') {
      navigate(`/experiences/${id}/thanks`, { replace: true });
    }
  };

  const sendFile = async(photo, id) => {
    return await post('/api/medias/draft', {
      formData: {
        file: photo.file,
        draftexperienceid: id,
      },
    });
  };

  const sendData = async(experience, id) => {
    const sanitized = {
      difficulty: experience.difficulty,
      sport: experience.sport,
      name: experience.name ? upperFirst(experience.name) : null,
      memorable: experience.memorable ? upperFirst(experience.memorable) : null,
      description: experience.description ? upperFirst(experience.description) : null,
    };

    await put(`/api/draft_experiences/${id}`, {
      data: sanitized,
    });
  };

  const sendUserData = async(userData) => {
    if (!userData) {
      return;
    }

    setUser({
      ...user,
      ...userData,
      isLoaded: true,
    });

    const sanitizedUser = {
      firstName: userData.firstName ? upperFirst(userData.firstName) : null,
    };

    if (sanitizedUser.firstName !== null) {
      await put('/api/users/me', {
        data: sanitizedUser,
      });
    }

    if (userData.avatar?.file) {
      await post('/api/avatars', {
        formData: {
          avatar: userData.avatar.file,
        },
      });
    }

    const sanitizedContributor = {
      description: userData.contributor.description,
      website: userData.contributor.website,
      socialMedia: {
        facebook: userData.contributor?.socialMedia?.facebook ? userData.contributor.socialMedia.facebook.replace(/^https:\/\/(www.)?facebook\.com(\/)?/i, '') : null,
        instagram: userData.contributor?.socialMedia?.instagram ? userData.contributor.socialMedia.instagram.replace(/^https:\/\/(www.)?instagram\.com(\/)?/i, '') : null,
        twitter: userData.contributor?.socialMedia?.twitter ? userData.contributor.socialMedia.twitter.replace(/^https:\/\/(www.)?twitter\.com(\/)?/i, '') : null,
      }
    };

    await put(`/api/contributors/${user.userId}`, {
      data: sanitizedContributor,
    });

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${user.token}`);

    const updatedUser = await get('/api/users/me');
    setUser({
      ...user,
      ...updatedUser,
      isLoaded: true,
    });
  };

  const handleAddPhoto = async(photo) => {
    try {
      const result = await sendFile(photo, id);

      // Do not update the picture url, to avoid loading it again
      delete result.url;

      return {
        ...photo,
        ...result,
        oldId: photo.id,
        isLoading: false,
        isTemporary: false,
        isInError: false,
      };
    } catch (e) {
      displayError(e);

      return {
        ...photo,
        oldId: photo.id,
        isLoading: false,
        isInError: true,
      };
    }
  };

  const handleDeletePhoto = async(photo) => {
    try {
      await del(`/api/medias/${photo.id}/draft`);

      return photo;
    } catch (e) {
      displayError(e);

      return photo;
    }
  };

  const handleDragEndPhotos = async(photos) => {
    try {
      return await put('/api/medias/draft/position', {
        data: {
          medias: photos.filter(photo => !photo.isTemporary).map(photo => ({
            id: photo.id,
            position: photo.position,
          })),
        },
      });
    } catch (e) {
      return displayError(e);
    }
  };

  const handleChangeThrottled = throttle(async({ values }) => {
    if (!values?.experience?.id) {
      return;
    }

    const experience = {
      ...values.experience,
    };

    delete experience.photos;
    delete experience.status;

    await localforage.setItem(`experience.${id}`, experience);
  }, 0);

  const handleSubmit = async(values) => {
    setSubmitting(true);

    try {
      await sendData(values.experience, id);
      await sendUserData(values.user);
    } catch (e) {
      setSubmitting(false);

      return displayError(e, () => actionRedirect('back'));
    }

    // Everything went well, remove the local save
    await localforage.removeItem(`experience.${id}`);

    // Launch publication
    try {
      const duration = values.experience.duration || predictDurationFromGeojson(values.experience.distance, { positiveElevation: values.experience.positiveElevation }, values.experience.sport) || 0;

      await put(`/api/draft_experiences/${id}`, {
        data: {
          duration,
          toPublish: true,
        },
      });

      pushAnalytics('Creation Completed', {
        'nb_of_images': values.experience.photos.length,
        time: (Number(new Date()) - pageTime) / 1000,
      });

      return actionRedirect('success');
    } catch (e) {
      return displayError(e);
    } finally {
      if (isMounted) {
        setSubmitting(false);
      }
    }
  };

  const handleCancel = async(values) => {
    setSubmitting(true);

    const experience = await localforage.getItem(`experience.${id}`);

    if (type !== 'draft' || !experience) {
      return actionRedirect('back');
    }

    try {
      await sendData(experience, id);
      await sendUserData(values?.user);

      return actionRedirect('back');
    } catch (e) {
      return displayError(e, () => actionRedirect('back'));
    } finally {
      if (isMounted) {
        setSubmitting(false);
      }
    }
  };

  const handleConfirmDelete = async() => {
    setIsPopupDeleteOpen(true);
  };

  const onDelete = async() => {
    try {
      await del(`/api/draft_experiences/${id}`);
      await localforage.removeItem(`experience.${id}`);

      return actionRedirect('delete');
    } catch (e) {
      return displayError(e, () => actionRedirect('delete'));
    }
  };

  return (
    <>
      {(experience.status ?? type) === 'draft' ? (
        <ExperienceForm
          disabled={type !== 'draft' || experience.status !== 'draft' || submitting}
          experience={experience}
          onAddPhoto={handleAddPhoto}
          onCancel={handleCancel}
          onChange={handleChangeThrottled}
          onDelete={handleConfirmDelete}
          onDeletePhoto={handleDeletePhoto}
          onDragEndPhotos={handleDragEndPhotos}
          onSubmit={handleSubmit}
          submitting={submitting}
        />
      ) : (
        <ExperiencePreview
          experience={experience}
          onCancel={handleCancel}
          onDelete={handleConfirmDelete}
        />
      )}

      <PopupDelete
        action={<FormattedMessage id="experience.remove" />}
        label={<FormattedMessage id="experience.remove.warning" />}
        onChangeState={setIsPopupDeleteOpen}
        onConfirm={onDelete}
        open={isPopupDeleteOpen}
        title={<FormattedMessage id="experience.remove?" />}
      />
    </>
  );
}

Experience.propTypes = {
  type: PropTypes.string,
};
