import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export const Item = forwardRef(({
  children,
  className = '',
  cols,
  containerSize,
  disabled,
  index,
  isDragging,
  ratio = 0.64,
  style,
  ...props
}, ref) => {
  const [ size, setSize ] = useState(null);

  useEffect(() => {
    setSize(containerSize ? (containerSize / cols * ratio) : null);
  }, [ containerSize, cols, ratio ]);

  const inlineStyles = {
    cursor: isDragging && 'grabbing',
    transformOrigin: '0 0',
    height: index === 0 ? size * cols : size,
    gridRowStart: index === 0 ? `span ${cols}` : null,
    gridColumnStart: index === 0 ? `span ${cols}` : null,
    ...style,
  };

  const classNames = [
    'Item',
    className,
  ];

  return (
    <div className={clsx(classNames)} ref={ref} style={inlineStyles} {...props}>
      {children}
    </div>
  );
});

Item.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  className: PropTypes.string,
  cols: PropTypes.number,
  containerSize: PropTypes.number,
  id: PropTypes.string.isRequired,
  isDragging: PropTypes.bool,
  ratio: PropTypes.number,
  style: PropTypes.object,
};
