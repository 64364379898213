import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from 'react-simple-observable-store';
import { LoadingScreen } from 'components/LoadingScreen';
import { ErrorScreen } from 'components/ErrorScreen';
import { FormattedMessage } from 'react-intl';

export function Login({ children }) {
  const [ user, setUser ] = useStore('user');
  const [ state, setState ] = useState({ fetching: true });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function fetchToken(token) {
      const response = await fetch(`/api/tokens/${token}/internal`, {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      });

      if (response.status < 200 || response.status >= 400) {
        setUser({ isLoaded: false });
        setState({ fetching: false });

        return;
      }

      const result = await response.json();

      return result?.token;
    }

    if (user.isLoaded === true) {
      setState({ fetching: false });

      return;
    }

    if (user.isLoaded !== undefined) {
      return;
    }

    if (user.token) {
      async function fetchUser() {
        setState({ fetching: true });

        if (user.isTokenFromUrl) {
          const internalToken = await fetchToken(user.token);

          window.localStorage.setItem('decathlonOutdoor.token', internalToken);
          setUser({
            token: internalToken,
            isTokenFromUrl: false,
          });

          navigate(location.pathname, { replace: true });

          return;
        }

        const headers = new Headers();
        headers.set('Authorization', `Bearer ${user.token}`);

        const response = await fetch('/api/users/me', {
          method: 'GET',
          headers: headers,
          mode: 'cors',
          cache: 'default',
        });

        if (response.status < 200 || response.status >= 400) {
          setUser({ isLoaded: false });
          setState({ fetching: false });

          return;
        }

        const result = await response.json();

        setUser({
          ...user,
          ...result,
          isLoaded: true,
        });

        setState({ fetching: false });
      }

      fetchUser();

      return;
    }

    if (!user.token) {
      setState({ fetching: false });
      setUser({ isLoaded: false });
    }

  }, [user.token]);

  if (state.fetching) {
    return (
      <div className="Login fetching">
        <LoadingScreen />
      </div>
    );
  }

  if (!user.isLoaded) {
    return (
      <ErrorScreen>
        <FormattedMessage id="loading.error" />
      </ErrorScreen>
    );
  }

  return (
    <div className="Login success">
      {children}
    </div>
  );
}

Login.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
};
