import './style/index.scss';

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import { FormattedMessage } from 'react-intl';

import {
  DndContext,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as BinIcon } from 'icons/bin.svg';
import { Grid } from './Grid';
import { SortableItem } from './SortableItem';
import { Item } from './Item';
import { customCollisionDetectionStrategy } from './customCollisionDetectionStrategy';
import { supportsTouch } from 'utils/device';

export function GridDrag({
  afterAddElement,
  afterAddElementDisplayTime = 3000,
  canAdd = true,
  cols = 3,
  disabled = false,
  errorElement,
  featuredElement,
  items = [],
  loaderElement,
  maxItems = 6,
  onDragEnd = () => {},
  onRemove = () => {},
  placeholderProps,
}) {
  const [ itemsToDisplay, setItemsToDisplay ] = useState([]);
  const [ active, setActive ] = useState(null);
  const [ isDraggingOverActions, setIsDraggingOverActions ] = useState(false);
  const [ displayAfterAddElement, setDisplayAfterAddElement ] = useState();
  const [ offsetWidth, setOffsetWidth ] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (afterAddElement && afterAddElementDisplayTime && itemsToDisplay.length === 0 && items.length > 0) {
      setDisplayAfterAddElement(true);
    }

    setItemsToDisplay(items);
  }, [items]);

  useEffect(() => {
    const throttled = throttle(() => {
      setOffsetWidth(ref?.current?.offsetWidth);
    }, 300);

    window.addEventListener('resize', throttled);

    setOffsetWidth(ref?.current?.offsetWidth);

    return () => {
      window.removeEventListener('resize', throttled);
      throttled.cancel();
    };
  }, [ref.current]);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 150,
      tolerance: 5,
    },
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  function handleDragEnd({ active, over }) {
    setActive(null);
    setIsDraggingOverActions(false);

    const index = items.findIndex(item => item.id === active.id);
    if (index === -1) {
      return false;
    }

    if (over.id === 'actions') {
      return onRemove(active.id);
    }

    if (active.id !== over.id) {
      const oldIndex = itemsToDisplay.findIndex(item => item.id === active.id);
      const newIndex = itemsToDisplay.findIndex(item => item.id === over.id);

      const moved = arrayMove(itemsToDisplay, oldIndex, newIndex);

      setItemsToDisplay(moved);
      onDragEnd(moved);
    }
  }

  function handleDragStart(e) {
    setActive(e.active.id !== 'actions' ? e.active.id : null);
    setDisplayAfterAddElement(false);
  }

  function handleClick() {
    setDisplayAfterAddElement(false);
  }

  function handleDragMove({ over }) {
    if (over?.id === 'actions') {
      return setIsDraggingOverActions(true);
    }

    if (isDraggingOverActions) {
      setIsDraggingOverActions(false);
    }
  }

  function handleDragCancel() {
    setActive(null);
    setIsDraggingOverActions(false);
  }

  const activeItem = active && itemsToDisplay.find(item => item.id === active);

  const isDragPossible = supportsTouch();
  const ItemComponent = isDragPossible ? SortableItem : Item;

  const Items = [];
  for (let index = 0; index < maxItems; index++) {
    const item = itemsToDisplay[index];
    const isDisabled = item?.isLoading || item?.isInError || disabled;

    if (item) {
      Items.push(
        <ItemComponent
          className={clsx(item.isLoading && 'loading', item.isInError && 'error', isDisabled && 'disabled')}
          disabled={isDisabled}
          id={item.id}
          index={index}
          key={item.id}
          containerSize={offsetWidth}
          cols={offsetWidth >= 984 ? maxItems : cols}
          onClick={handleClick}
        >
          {active !== item.id && item.el}

          {loaderElement}
          {index === 0 && !active && featuredElement}
          {index === 0 && displayAfterAddElement && isDragPossible && afterAddElement}
          {item.isInError && errorElement}
        </ItemComponent>
      );
    } else {
      Items.push(
        <Item
          className={clsx('placeholder', disabled && 'disabled')}
          cols={offsetWidth >= 984 ? maxItems : cols}
          containerSize={offsetWidth}
          disabled
          id={String(index + 1)}
          index={index}
          key={index + 1}
          {...placeholderProps}
        >
          <div className={clsx('inner', index === 0 && 'featured')}>
            {index === 0 ?
              <FormattedMessage id="photos.featured" /> :
              <FormattedMessage id="photos.nth" values={{ n: index + 1 }} />
            }
          </div>
        </Item>
      );
    }
  }

  Items.push(
    <ItemComponent
      className={clsx('placeholder', active === null ? 'add' : 'remove', isDraggingOverActions && 'over', (disabled || !canAdd) && 'disabled')}
      cols={offsetWidth >= 984 ? maxItems : cols}
      containerSize={offsetWidth}
      disabled
      id="actions"
      index={maxItems}
      key={maxItems + 1}
      {...placeholderProps}
    >
      {active === null ? (
        <div className="inner">
          <div className="icon-placeholder">
            <span className="icon">
              <PlusIcon />
            </span>
          </div>

          <span className="label">
            <FormattedMessage id="add" />
          </span>
        </div>
      ) : (
        <div className="inner">
          <div className="icon-placeholder">
            <span className="icon">
              <BinIcon />
            </span>
          </div>

          <span className="label">
            <FormattedMessage id="delete" />
          </span>
        </div>
      )}
    </ItemComponent>
  );

  return (
    <div className="GridDrag" ref={ref}>
      <DndContext
        collisionDetection={customCollisionDetectionStrategy}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragMove={handleDragMove}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <SortableContext items={itemsToDisplay} strategy={rectSortingStrategy}>
          <Grid columns={offsetWidth >= 984 ? maxItems : cols}>
            {Items}
          </Grid>
        </SortableContext>

        <DragOverlay adjustScale={true}>
          {activeItem ? (
            <Item
              index={itemsToDisplay.findIndex(item => item.id === active)}
              id={active}
              isDragging={Boolean(activeItem)}
              containerSize={offsetWidth}
              cols={offsetWidth >= 984 ? maxItems : cols}
            >
              {activeItem.el}
            </Item>
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

GridDrag.propTypes = {
  afterAddElement: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  afterAddElementDisplayTime: PropTypes.number,
  canAdd: PropTypes.bool,
  cols: PropTypes.number,
  disabled: PropTypes.bool,
  errorElement: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  featuredElement: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  items: PropTypes.array.isRequired,
  loaderElement: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  maxItems: PropTypes.number,
  onDragEnd: PropTypes.func,
  onRemove: PropTypes.func,
  placeholderProps: PropTypes.object,
};
