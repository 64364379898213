import './style/index.scss';
import 'swiper/scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { Image } from 'cloudinary-react';
import { FormattedMessage } from 'react-intl';
import { FieldError } from 'components/FieldError';
import { ReactComponent as CameraIcon } from 'icons/camera.svg';
import { ReactComponent as GlobeIcon } from 'icons/globe.svg';
import { ReactComponent as RefreshIcon } from 'icons/refresh.svg';
import { ReactComponent as DragDropIcon } from 'icons/drag-drop.svg';
import { ReactComponent as BinIcon } from 'icons/bin.svg';
import { toast } from 'react-toastify';
import { GridDrag } from 'components/GridDrag';
import clsx from 'clsx';
import { resizeImage } from 'utils/file';
import { displayError } from 'utils/error';
import { supportsTouch } from 'utils/device';
import { Loader } from 'components/Loader';
import { Chip } from 'components/Chip';

const acceptedFormats = [
  'image/*',
  '.heif',
];

export function FieldUpload({ input, meta, example, label, shared, maxLength = 1, required, ...props }) {
  const [ files, setFiles ] = useState([]);
  const [ canAddFile, setCanAddFile ] = useState(!props.disabled);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ remainingFiles, setRemainingFiles ] = useState(maxLength - files.length);
  const field = useField(input.name);
  const lastNameSegment = input.name.split('.');

  const classNames = [
    lastNameSegment[lastNameSegment.length - 1],
    meta.touched && meta.error && 'error',
    props.disabled && 'disabled',
  ];

  // Make sure to revoke the data uris when destroying the field to avoid memory leaks
  useEffect(() => () => {
    if (files?.length) {
      files.forEach(file => file.url.indexOf('blob:') === 0 && URL.revokeObjectURL(file.url));
    }
  }, []);

  useEffect(() => {
    setFiles((input.value || [])
      .map(data => ({
        ...data,
        url: !data.url && data.file ? URL.createObjectURL(data.file) : data.url,
      }))
      .sort((a, b) => a.position - b.position))
    ;

    setCanAddFile(input.value.length < maxLength && !props.disabled);
    setRemainingFiles(maxLength - input.value.length);

  }, [ input.value, props.disabled, maxLength ]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept ?? acceptedFormats.join(','),
    onDrop: async acceptedFiles => {
      let filesToAdd = acceptedFiles
        .filter((file, i) => i < remainingFiles)
      ;

      // Create initial miniatures
      filesToAdd = filesToAdd.map((file, i) => ({
        file,
        url: null,
        id: `${Number(new Date())}-${i}`,
        isTemporary: true,
        isLoading: true,
      }));

      if (acceptedFiles.length > remainingFiles) {
        toast.warn(<FormattedMessage id="experience.field.photos.warning" />, {
          icon: <CameraIcon />,
        });
      }

      // Add new items to existing items
      filesToAdd = [
        ...files,
        ...filesToAdd,
      ];

      // Add position
      filesToAdd = filesToAdd.map((file, i) => ({
        ...file,
        position: i + 1,
      }));

      setFiles(filesToAdd);
      field.input.onChange(filesToAdd);

      setIsLoading(true);

      // Create miniatures
      for await (const promise of filesToAdd.filter(data => data.file && data.isTemporary).map(data => async() => await resizeImage(data))) {
        try {
          const data = await promise();

          const index = filesToAdd.findIndex(f => f.id === data.id);

          if (index > -1) {
            filesToAdd[index] = data;

            setFiles([...filesToAdd]);
            field.input.onChange([...filesToAdd]);
          } else {
            throw new Error('index in files not found while creating miniature');
          }
        } catch (e) {
          displayError(e);
        }
      }

      // Upload files
      if (props.uploadMethod) {
        for await (const promise of filesToAdd.filter(data => data.file && data.isTemporary).map(file => async() => await props.uploadMethod(file))) {
          try {
            const result = await promise();

            // Upload finished
            const index = filesToAdd.findIndex(file => file.id === result.oldId);
            if (index > -1) {
              filesToAdd = [...filesToAdd];
              filesToAdd[index] = result;

              field.input.onChange(filesToAdd);
              setFiles(filesToAdd);
            } else {
              throw new Error('index in files not found while uploading file');
            }
          } catch (e) {
            displayError(e);
          }
        }
      } else {
        setFiles(filesToAdd.map(file => ({
          ...file,
          isLoading: false,
        })));
      }

      setIsLoading(false);
    }
  });

  const handleRetry = async id => {
    let filesToUpload = [...files];

    const index = filesToUpload.findIndex(file => file.id === id);

    try {
      if (index > -1) {
        filesToUpload[index] = {
          ...filesToUpload[index],
          isLoading: true,
          isInError: false,
        };
      } else {
        throw new Error('index in files not found while retrying upload');
      }
    } catch (e) {
      displayError(e);
    }

    setFiles(filesToUpload);

    const result = await props.uploadMethod(filesToUpload[index]);

    filesToUpload = [...files];
    filesToUpload[index] = result;

    setFiles(filesToUpload);
    field.input.onChange(filesToUpload);
  };

  const handleRemove = async id => {
    let newFiles = [...files];

    const index = files.findIndex(file => String(file.id) === String(id));

    try {
      if (index > -1) {
        if (!newFiles[index].isTemporary && props.deleteMethod) {
          props.deleteMethod(newFiles[index]);
        }

        newFiles.splice(index, 1);

        newFiles = newFiles.map((file, i) => ({
          ...file,
          position: i + 1,
        }))
          .sort((a, b) => a.position - b.position);

        setFiles(newFiles);
        field.input.onChange(newFiles);
      } else {
        throw new Error('index in files not found while removing file');
      }
    } catch (e) {
      displayError(e);
    }
  };

  const handleDragEnd = async order => {
    const newFiles = [...files];


    newFiles.forEach(file => {
      const position = order.findIndex(el => String(el.id) === String(file.id));

      try {
        if (position > -1) {
          file.oldPosition = file.position;
          file.position = position + 1;
        } else {
          throw new Error('index in files not found while dragging file');
        }
      } catch (e) {
        displayError(e);
      }
    });

    newFiles.sort((a, b) => a.position - b.position);

    if (props.dragendMethod) {
      props.dragendMethod(newFiles);
    }

    setFiles(newFiles);
    field.input.onChange(newFiles);
  };

  const isDragPossible = supportsTouch();

  return (
    <div className={clsx('Field FieldUpload', ...classNames)} onClick={e => input.onBlur(e)}>
      <div className="label">
        {label}
      </div>

      <section className="container">
        <div>
          <GridDrag
            afterAddElement={
              <div className="after-add-element">
                <div className="inner">
                  <div className="icon">
                    <DragDropIcon />
                  </div>
                  <div className="label">
                    <FormattedMessage id="photos.afterAddElement" />
                  </div>
                </div>
              </div>
            }
            cols={3}
            disabled={props.disabled || isLoading}
            canAdd={canAddFile || !isLoading}
            featuredElement={<Chip className="featured" variant={4}>
              <FormattedMessage id="featured" />
            </Chip>}
            items={files.map((data, i) => {
              return ({
                ...data,
                id: String(data.id),
                el: <>
                  {data.publicId ? (
                    <Image
                      crop="fill"
                      dpr="auto"
                      fetchFormat="auto"
                      gravity="auto"
                      loading="lazy"
                      publicId={data.publicId}
                      quality="auto"
                      responsive
                      width="auto"
                    />

                  ) : (
                    <img src={data.url} alt="" />
                  )}

                  {!isDragPossible && <Chip className="removeAction" variant={3}>
                    <span className="icon" onClick={() => handleRemove(data.id)}>
                      <BinIcon />
                    </span>
                  </Chip>}

                  {data.isInError && props.uploadMethod && (
                    <div className="error-content" onClick={() => handleRetry(data.id)}>
                      <div className="icon-placeholder">
                        <span className="icon">
                          <RefreshIcon />
                        </span>
                      </div>

                      <span className="label">
                        <FormattedMessage id="retry" />
                      </span>
                    </div>
                  )}
                </>
              });
            }).filter(Boolean)}
            loaderElement={<Loader className="loader" variant={2} />}
            maxItems={maxLength}
            onDragEnd={handleDragEnd}
            onRemove={handleRemove}
            placeholderProps={canAddFile && !isLoading ? getRootProps() : null}
          />
          <input {...getInputProps()} />
        </div>
      </section>

      <div className="field-footer">
        <FieldError name={input.name} />

        <div className="extra">
          {!required && (
            <div className="facultative"><FormattedMessage id="field.facultative" /></div>
          )}
        </div>
      </div>

      {example && (
        <div className="example">{example}</div>
      )}

      {shared && (
        <div className="shared">
          <span className="icon">
            <GlobeIcon />
          </span>
          <span className="label"><FormattedMessage id={`shared.${shared}`} /></span>
        </div>
      )}
    </div>
  );
}

FieldUpload.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  example: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  maxLength: PropTypes.number,
  shared: PropTypes.oneOf(['all']),
  required: PropTypes.bool,
};
