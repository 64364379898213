import './style/index.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-simple-observable-store';
import { FormattedMessage, useIntl } from 'react-intl';
import { ExperienceActions } from 'components/ExperienceActions';
import { Form, Field, FormSpy } from 'react-final-form';
import { formatDistance, latlngDistance, predictDurationFromGeojson } from 'utils/geo';
import { useDistance } from 'hooks/useDistance';
import { FieldTextarea } from 'components/FieldTextarea';
import { FieldSelect } from 'components/FieldSelect';
import { FieldUpload } from 'components/FieldUpload';
import { Contributor } from 'components/Contributor';
import { Btn } from 'components/Btn';
import { Faq } from 'components/Faq';
import { Map } from 'components/Map';
import { useValidators } from 'hooks/useValidators';
import { ReactComponent as BinIcon } from 'icons/bin.svg';
import round from 'lodash/round';
import { GeoInfos } from 'components/GeoInfos';
import { useAnalytics } from 'hooks/useAnalytics';
import { usePreviousLocation } from 'hooks/usePreviousLocation';

export function ExperienceForm({
  disabled = false,
  experience,
  onAddPhoto,
  onCancel,
  onChange,
  onDelete,
  onDeletePhoto,
  onDragEndPhotos,
  onSubmit,
  submitting = false,
}) {
  const [user] = useStore('user');
  const [configs] = useStore('configs');
  const intl = useIntl();
  const position = useDistance(experience.status === 'published');
  const { required, requiredMultiple, composeValidators, minLength } = useValidators();
  const { pushAnalytics } = useAnalytics();

  const [ values, setValues ] = useState({});

  const {
    previousPath,
  } = usePreviousLocation();

  const startPoint = experience.geojson?.geometry?.coordinates?.[0];

  const startDistance = position && startPoint ? formatDistance(latlngDistance(
    [ position.coords.latitude, position.coords.longitude ],
    [ startPoint[1], startPoint[0] ]
  )) : null;

  const difficulties = configs.types.filter(el => el.type === 'difficulties').map(el => el.code);
  const sports = configs.types.filter(el => el.type === 'sports' && [ 'pedestre', 'vtc', 'vtt', 'velo-route', 'gravel' ].includes(el.code)).map(el => el.code);

  function handlePreSubmit(handleSubmit, ...args) {
    setTimeout(() => {
      const el = document.querySelector('.error');

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);

    return handleSubmit(...args);
  }

  useEffect(() => {
    pushAnalytics('Completion Page Viewed', {
      from: previousPath,
    });
  }, [previousPath]);

  useEffect(() => {
    const userForForm = {
      ...user,
      contributor: {
        ...user.contributor,
        socialMedia: {
          facebook: user.contributor?.socialMedia?.facebook ? 'https://www.facebook.com/' + user.contributor.socialMedia.facebook : null,
          instagram: user.contributor?.socialMedia?.instagram ? 'https://www.instagram.com/' + user.contributor.socialMedia.instagram : null,
          twitter: user.contributor?.socialMedia?.twitter ? 'https://twitter.com/' + user.contributor.socialMedia.twitter : null,
        }
      }
    };

    setValues({ experience: experience ?? {}, user: userForForm });
  }, [experience]);

  return (
    <div className="ExperienceForm">
      <Form onSubmit={onSubmit} initialValues={values} mutators={{
        setValue: ([ field, value ], state, { changeValue }) => {
          changeValue(state, field, () => value);
        }
      }}>
        {formProps => {
          const duration = experience.duration || predictDurationFromGeojson(experience.distance, { positiveElevation: experience.positiveElevation }, formProps.values.experience?.sport);

          return (
            <form onSubmit={(...args) => handlePreSubmit(formProps.handleSubmit, ...args)}>
              <FormSpy
                subscription={{ values: true, dirty: true }}
                onChange={onChange}
              />

              <section className="page-content">
                <ExperienceActions
                  title={<>
                    <span className="label">
                      <FormattedMessage id="experience.complete" />
                    </span>
                  </>}
                  disabled={disabled}
                  submitting={submitting}
                  onCancel={() => onCancel(formProps.values)}
                />
              </section>

              <section className="page-content">
                <Field
                  name="experience.name"
                  component={FieldTextarea}
                  validate={required}
                  required
                  maxLength={60}
                  disabled={disabled}
                  placeholder={intl.formatMessage({ id: 'experience.field.name.placeholder' })}
                  example={intl.formatMessage({ id: 'experience.field.name.example' })}
                  parse={value => value.replace(/\r?\n/g, '')}
                />
              </section>

              <section className="page-content">
                <h2><FormattedMessage id="experience.field.photos.label" /></h2>

                <Field
                  name="experience.photos"
                  component={FieldUpload}
                  validate={requiredMultiple}
                  required
                  maxLength={6}
                  disabled={disabled}
                  example={intl.formatMessage({ id: 'experience.field.photos.example' })}
                  shared="all"
                  uploadMethod={onAddPhoto}
                  deleteMethod={onDeletePhoto}
                  dragendMethod={onDragEndPhotos}
                />
              </section>

              <section className="page-content">
                <h2><FormattedMessage id="experience.field.memorable.label" /></h2>

                <Field
                  name="experience.memorable"
                  component={FieldTextarea}
                  validate={required}
                  required
                  maxLength={100}
                  disabled={disabled}
                  placeholder={intl.formatMessage({ id: 'experience.field.memorable.placeholder' })}
                  example={intl.formatMessage({ id: 'experience.field.memorable.example' })}
                  parse={value => value.replace(/\r?\n/g, '')}
                />
              </section>

              <section className="page-content">
                <GeoInfos
                  distance={experience.distance}
                  // positiveElevation={experience.positiveElevation}
                  duration={duration}
                />
              </section>

              <section className="page-content">
                <h2><FormattedMessage id="experience.details" /></h2>

                <Map geojson={experience.geojson} />

                <div className="details">
                  <Field
                    name="experience.sport"
                    component={FieldSelect}
                    validate={required}
                    disabled={disabled}
                    label={<FormattedMessage id="sport" />}
                    placeholder={intl.formatMessage({ id: 'choose' })}
                    required
                  >
                    {sports.map(code => <option key={code} value={code}>{intl.formatMessage({ id: code })}</option>)}
                  </Field>
                  <Field
                    name="experience.difficulty"
                    component={FieldSelect}
                    validate={required}
                    disabled={disabled}
                    label={<FormattedMessage id="difficulty" />}
                    placeholder={intl.formatMessage({ id: 'choose' })}
                    required
                  >
                    {difficulties.map(code => <option key={code} value={code}>{intl.formatMessage({ id: code })}</option>)}
                  </Field>
                </div>
              </section>

              <section className="page-content">
                <h2><FormattedMessage id="experience.field.description.label" /></h2>

                <Field
                  name="experience.description"
                  component={FieldTextarea}
                  minLength={150}
                  maxLength={1000}
                  disabled={disabled}
                  placeholder={intl.formatMessage({ id: 'experience.field.description.placeholder' })}
                  validate={composeValidators(required, minLength(150))}
                  required
                />

              </section>

              {Boolean(startPoint) && <section className="page-content">
                {startDistance ? (
                  <h2><FormattedMessage id="startAt" values={{ distance: startDistance }} /></h2>
                ) : (
                  <h2><FormattedMessage id="startPoint" /></h2>
                )}

                <Map className="Map MapStart" point={startPoint} />

                <div className="startPoint">
                  <div>{`${round(startPoint[1], 6)}, ${round(startPoint[0], 6)}`}</div>
                </div>
              </section>}

              <section className="page-content">
                <Contributor
                  user={formProps.values?.user ?? user}
                  editable
                  submitting={submitting}
                />
              </section>

              <section className="page-content">
                <Faq />
              </section>

              <section className="page-content alt">
                <Btn className="remove-link" variant={4} wide onClick={onDelete} disabled={disabled || submitting}>
                  <span className="icon">
                    <BinIcon />
                  </span>

                  <span className="label">
                    <FormattedMessage id="experience.remove" />
                  </span>
                </Btn>
              </section>

              <section className="page-content">
                <ExperienceActions
                  disabled={disabled}
                  submitting={submitting}
                  onCancel={() => onCancel(formProps.values)}
                />
              </section>
            </form>
          );
        }}
      </Form>
    </div>
  );
}

ExperienceForm.propTypes = {
  disabled: PropTypes.bool,
  experience: PropTypes.object.isRequired,
  onAddPhoto: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDeletePhoto: PropTypes.func,
  onDragEndPhotos: PropTypes.func,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
};
