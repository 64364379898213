import { useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';

const useScroll = () => {
  const cache = useRef();
  const [ y, setY ] = useState(window.scrollY);
  const [ direction, setDirection ] = useState('bottom');

  useEffect(() => {
    const throttled = throttle(() => {
      setY(window.scrollY);
      setDirection(window.scrollY >= (cache.current ?? 0) ? 'bottom' : 'top');

      cache.current = window.scrollY;
    }, 300);

    window.addEventListener('scroll', throttled);

    return () => {
      window.removeEventListener('scroll', throttled);
      throttled.cancel();
    };
  }, []);

  return { y, direction };
};

export {
  useScroll,
};
