import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

export function Loader({ variant, className }) {
  const classNames = [
    'Loader',
    className,
    variant && `variant-${variant}`,
  ];

  return (
    <div className={clsx(classNames)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

Loader.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.number,
};
