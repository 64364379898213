import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FieldError } from 'components/FieldError';

export function FieldTextarea({ input, meta, example, label, required, ...props }) {
  const lastNameSegment = input.name.split('.');

  const classNames = [
    lastNameSegment[lastNameSegment.length - 1],
    meta.touched && meta.error && 'error',
    props.disabled && 'disabled',
  ].filter(Boolean);

  return (
    <div className={`Field FieldTextarea ${classNames.join(' ')}`}>
      <label>
        <div className="label">
          {label}
        </div>

        <textarea
          {...input}
          {...props}
        />

        <div className="field-footer">
          <FieldError name={input.name} />

          <div className="extra">
            {!required && (
              <div className="facultative"><FormattedMessage id="field.facultative" /></div>
            )}

            {Boolean(props.maxLength) && (
              <div className="maxlength">{`${input.value.length}/${props.maxLength}`}</div>
            )}
          </div>
        </div>

        {example && (
          <div className="example">{example}</div>
        )}
      </label>
    </div>
  );
}

FieldTextarea.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  example: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
