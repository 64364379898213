import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { ReactComponent as ClockIcon } from 'icons/clock.svg';
import { ReactComponent as EditIcon } from 'icons/edit.svg';
import { ReactComponent as PedestrianIcon } from 'icons/pedestrian.svg';
import { ReactComponent as BikeIcon } from 'icons/bike.svg';
import { ReactComponent as GlobeIcon } from 'icons/globe.svg';
import { ReactComponent as CloseIcon } from 'icons/close-circle.svg';
import { Image } from 'cloudinary-react';
import { formatDuration } from 'utils/date';
import { formatDistance } from 'utils/geo';
import { Chip } from 'components/Chip';

const chipVariants = {
  'moderation': 1,
  'draft': 2,
  'rejected': 3,
};

export function ExperienceItem({ experience }) {
  const name = experience.name || (
    <FormattedMessage id="experience.name.withDate" values={{
      date: <FormattedDate
        value={new Date(experience.createdAt)}
        weekday="short"
        day="2-digit"
        month="long"
        hour="numeric"
        minute="numeric"
      />
    }} />
  );

  return (
    <div className={`ExperienceItem ${experience.status}`}>
      <div className="picture">
        {experience.photos?.[0]?.publicId ? (
          <Image
            publicId={experience.photos?.[0]?.publicId}
            quality="auto"
            fetchFormat="auto"
            gravity="auto"
            crop="fill"
            width="auto"
            loading="lazy"
            dpr="auto"
            responsive
          />
        ) : (
          <img src={experience.photos?.[0]?.url ?? 'https://res.cloudinary.com/easymountain/image/upload/v1631273278/decathlon-outdoor/dev/static/images/contribution/pattern.svg'} alt="" />
        )}

        {experience.status === 'published' && (
          <Chip className="status">
            <span className="icon">
              <GlobeIcon />
            </span>

            <span className="label">
              <FormattedMessage id={experience.status} />
            </span>
          </Chip>
        )}
      </div>

      <div className="content">
        <h3>{name}</h3>

        {experience.status === 'published' && <div className="infos">
          <span className="info">
            <span className="icon">
              {experience.sport === 'pedestre' ? <PedestrianIcon /> : <BikeIcon />}
            </span>
          </span>
          <span className="info">
            <FormattedMessage id={experience.sport} />
          </span>
          •
          <span className="info">
            {formatDuration(experience.duration)} ({formatDistance(experience.distance)} km)
          </span>
          •
          <span className="info">
            <FormattedMessage id={experience.difficulty} />
          </span>
        </div>}

        {experience.status !== 'published' && (
          <Chip className="status" variant={chipVariants[experience.status]}>
            <span className="icon">
              {experience.status === 'draft' && <EditIcon />}
              {experience.status === 'moderation' && <ClockIcon />}
              {experience.status === 'rejected' && <CloseIcon />}
            </span>

            <span className="label">
              <FormattedMessage id={experience.status} />
            </span>
          </Chip>
        )}
      </div>
    </div>
  );
}

ExperienceItem.propTypes = {
  experience: PropTypes.object.isRequired,
};
