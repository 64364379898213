import { closestCenter } from '@dnd-kit/core';

export function customCollisionDetectionStrategy({ active, collisionRect, droppableContainers }) {
  const actionsContainer = droppableContainers.find(({ id }) => id === 'actions');
  const bottom = actionsContainer.rect.current?.offsetTop + actionsContainer.rect.current?.height;

  if (bottom && !isNaN(bottom) && collisionRect.top > bottom) {
    // If the dragged item is bellow the diaporama bottom, exclude the actions from droppables
    return closestCenter({
      active,
      collisionRect,
      droppableContainers: droppableContainers.filter(({ id }) => id !== 'actions'),
    });
  }

  return closestCenter({
    active,
    collisionRect,
    droppableContainers: droppableContainers,
  });
};
