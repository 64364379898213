import React, { useEffect } from 'react';
import { device } from 'utils/device';
import { useLocation, useNavigate } from 'react-router-dom';


export function From({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const from = params.get('from');

    if (!window.sessionStorage.getItem('from') || from) {
      window.sessionStorage.setItem('from', from ?? (device().isDesktop ? 'desktop' : 'mobile'));
    }

    if (from) {
      navigate(location.pathname, { replace: true });
    }
  }, []);

  return (
    <>
      {children}
    </>
  );
}
