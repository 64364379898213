import { track } from '@amplitude/analytics-browser';

const useAnalytics = () => {
  return {
    pushAnalytics: (event, data = {}) => {
      track(event, data);
    }
  };
};

export {
  useAnalytics,
};
