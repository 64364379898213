import './style/index.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ExperienceCreateForm } from 'components/ExperienceCreateForm';
import { post } from 'utils/query';
import { displayError } from 'utils/error';
import { distanceFromGeojson, elevationFromGeojson } from 'utils/geo';
import { redirect } from 'utils/device';
import { useIsMounted } from 'hooks/useIsMounted';
import { usePreviousLocation } from 'hooks/usePreviousLocation';

export function ExperienceCreate() {
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const [ submitting, setSubmitting ] = useState(false);

  usePreviousLocation();

  const experience = { status: 'draft' };

  const sendData = async experience => {
    return await post('/api/draft_experiences', {
      headers: {
        'X-APP-VERSION': '4.14.0',
      },
      data: experience,
    });
  };

  const onSubmit = async(values) => {
    setSubmitting(true);

    try {
      const elevation = elevationFromGeojson(values.experience.geojson.data);
      const positiveElevation = Math.round(elevation.positiveElevation);
      const negativeElevation = Math.round(elevation.negativeElevation);
      const distance = Math.round(distanceFromGeojson(values.experience.geojson.data));

      let name = null;

      if (values.experience?.geojson?.data?.properties?.name) {
        name = values.experience.geojson.data.properties.name.length > 60 ?
          values.experience.geojson.data.properties.name.substring(0, 60 - 3) + '...' :
          values.experience.geojson.data.properties.name;
      }

      const sanitized = {
        ...values.experience,
        name,
        positiveElevation: positiveElevation ?? 0,
        negativeElevation: negativeElevation ? -negativeElevation : 0,
        distance,
        duration: 0,
        geojson: values.experience.geojson.data,
        geojsonOrigin: 'track import',
      };

      delete sanitized.geojson.properties;

      const result = await sendData(sanitized);

      navigate(`/drafts/${result.id}`, { replace: true });
    } catch (e) {
      return displayError(e, () => redirect(navigate));
    } finally {
      if (isMounted) {
        setSubmitting(false);
      }
    }
  };

  const onCancel = async() => {
    navigate(-1);
  };

  return (
    <>
      <ExperienceCreateForm
        experience={experience}
        submitting={submitting}
        disabled={submitting}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
}

ExperienceCreate.propTypes = {
  type: PropTypes.string,
};
