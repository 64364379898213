import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';

export function Chip({ children, className = '', variant }) {
  const classNames = [
    'Chip',
    ...className.split(' '),
    variant && `variant-${variant}`,
  ].filter(Boolean);

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
}

Chip.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]).isRequired,
  variant: PropTypes.number,
  className: PropTypes.string,
};
