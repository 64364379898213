import './style/index.scss';

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as HomeIcon } from 'icons/home.svg';
import { ReactComponent as ArrowRightIcon } from 'icons/arrow-right.svg';

export function Breadcrumb({ title }) {
  const intl = useIntl();

  const breadcrumb = [
    <a href={`${process.env.REACT_APP_WEBSITE}/fr-fr`} alt={intl.formatMessage({ id: 'breadcrumb.home' })}>
      <span className="icon">
        <HomeIcon />
      </span>
    </a>,
    <a href={`${process.env.REACT_APP_WEBSITE}/fr-fr/user`} alt="">
      <FormattedMessage id="breadcrumb.profile" />
    </a>,
    title,
  ];

  return (
    <div className="Breadcrumb">
      {breadcrumb.map((item, i) => <Fragment key={i}>
        <span>{item}</span>
        {i < breadcrumb.length - 1 && <span className="icon"><ArrowRightIcon /></span>}
      </Fragment>)}
    </div>
  );
}

Breadcrumb.propTypes = {
  title: PropTypes.any.isRequired,
};
