import './style/index.scss';
import 'swiper/scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { FieldError } from 'components/FieldError';
import { ReactComponent as GlobeIcon } from 'icons/globe.svg';
import clsx from 'clsx';
import { resizeImage } from 'utils/file';
import { displayError } from 'utils/error';
import { AvatarDisplay } from 'components/Contributor/AvatarDisplay';
import { Btn } from 'components/Btn';

const acceptedFormats = [
  '.png',
  '.jpeg',
  '.jpg',
];

export function FieldUploadAvatar({ input, meta, example, label, shared, required, ...props }) {
  const [ file, setFile ] = useState([]);
  const field = useField(input.name);
  const lastNameSegment = input.name.split('.');

  const classNames = [
    lastNameSegment[lastNameSegment.length - 1],
    meta.touched && meta.error && 'error',
    props.disabled && 'disabled',
  ];

  // Make sure to revoke the data uri when destroying the field to avoid memory leaks
  useEffect(() => () => {
    if (file?.url) {
      file.url.indexOf('blob:') === 0 && URL.revokeObjectURL(file.url);
    }
  }, []);

  useEffect(() => {
    if (input.value instanceof Object) {
      return setFile({
        ...input.value,
        url: !input.value?.url && input.value?.file ? URL.createObjectURL(input.value.file) : input.value.url,
      });
    }

    return setFile({
      url: input.value,
    });
  }, [input.value]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: props.accept ?? acceptedFormats.join(','),
    onDrop: async acceptedFiles => {
      try {
        const acceptedFile = await resizeImage({ file: acceptedFiles[0] }, 0.5);

        setFile(acceptedFile);
        field.input.onChange(acceptedFile);
      } catch (e) {
        displayError(e);
      }
    }
  });

  return (
    <div className={clsx('Field FieldUploadAvatar', ...classNames)} onClick={e => input.onBlur(e)}>
      <div className="label">
        {label}
      </div>

      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} disabled={props.disabled} />

          <div className="dropzone-content">
            <AvatarDisplay avatar={file?.url} />

            <div className="input">
              <Btn variant={5} rounded>
                <span className="label">
                  <FormattedMessage id={file ? 'photos.edit' : 'photos.add'} />
                </span>
              </Btn>
            </div>
          </div>
        </div>
      </section>

      <div className="field-footer">
        <FieldError name={input.name} />
      </div>

      {example && (
        <div className="example">{example}</div>
      )}

      {shared && (
        <div className="shared">
          <span className="icon">
            <GlobeIcon />
          </span>
          <span className="label"><FormattedMessage id={`shared.${shared}`} /></span>
        </div>
      )}
    </div>
  );
}

FieldUploadAvatar.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  example: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  maxLength: PropTypes.number,
  shared: PropTypes.oneOf(['all']),
  required: PropTypes.bool,
};
