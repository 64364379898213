import 'react-toastify/dist/ReactToastify.css';
import 'reactjs-popup/dist/index.css';
import './App.scss';

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createStore, StoreContext } from 'react-simple-observable-store';
import { init, setOptOut, Types } from '@amplitude/analytics-browser';
import { Login } from 'components/Login';
import { ScrollToTop } from 'components/ScrollToTop';
import { ExperienceList } from 'pages/ExperienceList';
import { Experience } from 'pages/Experience';
import { ExperienceCreate } from 'pages/ExperienceCreate';
import { Thanks } from 'pages/Thanks';
import { deviceClassNames } from 'utils/device';
import { IntlProvider } from 'react-intl';
import { CloudinaryContext } from 'cloudinary-react';
import { getTranslations } from 'translations';
import { DidomiSDK } from '@didomi/react';
import { didomiConfig } from 'config/didomi';
import { LoadConfigs } from './LoadConfigs';
import localforage from 'localforage';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from './ErrorBoundary';
import { From } from './From';

const params = new URL(window.location.href).searchParams;
const tokenFromUrl = params.get('token');
const token = window.localStorage.getItem('decathlonOutdoor.token');

const store = createStore({
  user: {
    token: tokenFromUrl || token,
    isTokenFromUrl: Boolean(tokenFromUrl),
  },
  configs: null,
  medias: {},
});

localforage.config({
  name: 'decathlon-outdoor',
  version: 1.0,
  storeName: 'contribution', // Should be alphanumeric, with underscores.
});

let isAmplitudeInit = false;
const initAmplitude = async() => {
  if (store.consent.getUserConsentStatusForVendor('c:amplitude-TWph43f6')) {
    if (!isAmplitudeInit) {
      await init(process.env.REACT_APP_AMPLITUDE_API_KEY, store.user?.userId, {
        serverZone: Types.ServerZone.EU,
      });

      isAmplitudeInit = true;
    }

    setOptOut(false);
  } else {
    setOptOut(true);
  }
};

export function App() {
  return (
    <IntlProvider messages={getTranslations()} locale="fr" defaultLocale="en">
      <Router>
        <StoreContext.Provider value={store}>
          <ErrorBoundary>
            <CloudinaryContext cloudName="easymountain">
              <div className={`App ${deviceClassNames()}`}>
                <From>
                  <LoadConfigs>
                    <Login>
                      <ScrollToTop />
                      <Routes>
                        <Route path="/experiences" element={<ExperienceList />} />
                        <Route path="/experiences/:id" element={<Experience type="experience" />} />
                        <Route path="/experiences/:id/thanks" element={<Thanks />} />
                        <Route path="/drafts/create" element={<ExperienceCreate />} />
                        <Route path="/drafts/:id" element={<Experience type="draft" />} />
                        <Route index element={<ExperienceList />} />
                      </Routes>
                    </Login>

                    <DidomiSDK
                      config={didomiConfig}
                      iabVersion={2}
                      gdprAppliesGlobally={true}
                      onReady={didomi => {
                        store.consent = didomi;

                        initAmplitude();
                      }}
                      onConsentChanged={() => initAmplitude()}
                      onNoticeShown={() => console.log('Didomi Notice Shown')}
                      onNoticeHidden={() => console.log('Didomi Notice Hidden')}
                    />
                  </LoadConfigs>
                </From>

                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={true}
                  newestOnTop
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                />
              </div>
            </CloudinaryContext>
          </ErrorBoundary>
        </StoreContext.Provider>
      </Router>
    </IntlProvider>
  );
}
