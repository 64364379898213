import './style/index.scss';
import 'swiper/scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Image } from 'cloudinary-react';

export function Diaporama({ photos }) {
  return (
    <div className="Diaporama">
      <section className="container">
        <Swiper className="diaporama" slidesPerView={photos?.length > 1 ? 1.1 : 1} spaceBetween={8} pagination={true} modules={[Pagination]}>
          {photos?.length > 0 && photos.map((data, i) => (
            <SwiperSlide key={i}>
              {data.publicId ? (
                <Image
                  publicId={data.publicId}
                  quality="auto"
                  fetchFormat="auto"
                  gravity="auto"
                  crop="fill"
                  width="auto"
                  loading="lazy"
                  dpr="auto"
                  responsive
                />
              ) : (
                <img src={data.url} alt="" />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
}

Diaporama.propTypes = {
  photos: PropTypes.array.isRequired,
};
