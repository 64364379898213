import './style/index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { ExperienceActions } from 'components/ExperienceActions';
import { Form, Field } from 'react-final-form';
import { distanceFromGeojson, durationFromGeojson, elevationFromGeojson } from 'utils/geo';
import { useConnection } from 'hooks/useConnection';
import { FieldGeographical } from 'components/FieldGeographical';
import { useValidators } from 'hooks/useValidators';
import { GeoInfos } from 'components/GeoInfos';

export function ExperienceCreateForm({ experience, disabled = false, submitting = false, onSubmit, onCancel }) {
  const intl = useIntl();
  const { required } = useValidators();

  useConnection(experience.status === 'draft');

  function handlePreSubmit(handleSubmit, ...args) {
    setTimeout(() => {
      const el = document.querySelector('.error');

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);

    return handleSubmit(...args);
  }

  return (
    <div className="ExperienceCreateForm">
      <Form onSubmit={onSubmit} initialValues={{ experience }} mutators={{
        setValue: ([ field, value ], state, { changeValue }) => {
          changeValue(state, field, () => value);
        }
      }}>
        {formProps => {
          let distance = null;
          let positiveElevation = null;
          let duration = null;

          const geojson = formProps.values.experience?.geojson?.data;
          const fileInfo = formProps.values.experience?.geojson?.info;

          if (geojson) {
            const elevation = elevationFromGeojson(geojson);

            duration = durationFromGeojson(geojson);
            distance = Math.round(distanceFromGeojson(geojson));
            positiveElevation = elevation.positiveElevation;

            if (formProps.values.experience.distance === undefined) {
              formProps.form.mutators.setValue('experience.distance', distance);
            }

            if (formProps.values.experience.duration === undefined) {
              formProps.form.mutators.setValue('experience.duration', duration);
            }

            if (formProps.values.experience.positiveElevation === undefined) {
              formProps.form.mutators.setValue('experience.positiveElevation', positiveElevation);
            }
          }

          return (
            <form onSubmit={(...args) => handlePreSubmit(formProps.handleSubmit, ...args)}>
              <section className="page-content">
                <ExperienceActions
                  title={<>
                    <span className="label">
                      <FormattedMessage id="experience.preview" />
                    </span>
                  </>}
                  cancelLabel={<FormattedMessage id="cancel" />}
                  submitLabel={<FormattedMessage id="continue" />}
                  disabled={disabled}
                  submitting={submitting}
                  onCancel={onCancel}
                />
              </section>

              {geojson && (
                <>
                  <section className="page-content">
                    {Boolean(geojson?.properties?.time) && <div className="date">
                      <FormattedDate
                        value={new Date(geojson.properties.time)}
                        year="numeric"
                        weekday="short"
                        day="2-digit"
                        month="short"
                        hour="numeric"
                        minute="numeric"
                      />
                    </div>}

                    <h2>{geojson?.properties?.name || <FormattedMessage id="experience.name" />}</h2>

                    <div className="filename">
                      <div className="label"><FormattedMessage id="filename" /> :</div>
                      {fileInfo.name}
                    </div>
                  </section>

                  <section className="page-content">
                    <GeoInfos
                      distance={distance}
                      // positiveElevation={positiveElevation}
                      duration={duration}
                    />
                  </section>
                </>
              )}

              <section className="page-content wide collapsed">
                <Field
                  name="experience.geojson"
                  component={FieldGeographical}
                  validate={required}
                  required
                  maxLength={1}
                  disabled={disabled}
                  placeholder={intl.formatMessage({ id: 'experience.field.gpx.placeholder' })}
                />
              </section>
            </form>
          );
        }}
      </Form>
    </div>
  );
}

ExperienceCreateForm.propTypes = {
  experience: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};
