import './style/index.scss';

import React from 'react';

export function Logo() {
  return (
    <div className="Logo">
      <a href={`${process.env.REACT_APP_WEBSITE}/fr-fr/user`} alt="">
        <svg viewBox="0 0 338 37">
          <path
            d="M178 6.72266C176.5 5.19478 174.7 3.97248 172.6 3.15761C170.5 2.34274 168.1 1.9353 165.5 1.9353C162.9 1.9353 160.6 2.34274 158.6 3.25947C156.6 4.17619 154.8 5.3985 153.3 6.92638C151.8 8.45426 150.7 10.2877 149.9 12.3249C149.1 14.3621 148.7 16.7048 148.7 19.0476C148.7 21.6959 149.1 24.1405 149.9 26.2795C150.7 28.4186 151.8 30.252 153.3 31.8818C154.8 33.4096 156.6 34.6319 158.7 35.4468C160.8 36.2617 163.2 36.6691 165.9 36.6691C168.5 36.6691 170.8 36.2617 172.8 35.3449C174.9 34.5301 176.6 33.3078 178.1 31.678C179.6 30.1502 180.7 28.2148 181.5 26.0758C182.3 23.9368 182.7 21.4922 182.7 18.9457C182.7 16.3992 182.3 14.1583 181.5 12.0193C180.6 9.98214 179.5 8.25054 178 6.72266ZM171.2 28.7241C170.9 29.7427 170.4 30.4557 169.8 30.965C169.2 31.4743 168.5 31.678 167.7 31.678C166.8 31.678 166 31.3725 165.2 30.6595C164.4 29.9464 163.7 29.0297 163 27.8074C162.3 26.5851 161.7 25.2609 161.2 23.7331C160.7 22.2052 160.3 20.5754 160 18.8438C159.7 17.1122 159.6 15.4825 159.6 13.7509C159.6 12.223 159.8 11.0007 160.1 9.98214C160.4 8.96355 160.9 8.25054 161.4 7.74125C162 7.23195 162.7 6.92638 163.5 6.92638C164.4 6.92638 165.2 7.33381 166 8.04682C166.8 8.75983 167.5 9.67656 168.2 10.8989C168.9 12.1212 169.5 13.5472 170 15.0751C170.5 16.603 170.9 18.2327 171.2 19.9643C171.5 21.6959 171.6 23.3256 171.6 24.9554C171.7 26.3814 171.5 27.7055 171.2 28.7241Z" />
          <path
            d="M211 31.8818L210.7 31.6781C210.5 31.4744 210.4 31.2707 210.3 30.9651C210.2 30.6595 210.1 30.1502 210.1 29.3354V15.177C210.1 14.5658 209.9 14.0566 209.6 13.6491C209.3 13.2417 208.8 13.038 208.2 13.038C207.7 13.038 207.2 13.1398 206.8 13.2417C206.3 13.3435 205.7 13.6491 204.9 13.9547L200.3 15.7881C199.6 16.0937 199.2 16.3993 198.9 16.7049C198.6 17.0105 198.5 17.4179 198.5 17.9272C198.5 18.3346 198.6 18.6402 198.8 18.9458C199 19.2513 199.3 19.4551 199.6 19.6588L199.9 19.8625C200.2 20.0662 200.4 20.2699 200.5 20.4736C200.6 20.7792 200.7 21.1867 200.7 21.8997V28.5205C200.3 28.9279 199.9 29.2335 199.4 29.3354C199 29.5391 198.6 29.5391 198.1 29.5391C197.5 29.5391 197 29.3354 196.6 28.8261C196.2 28.3168 196.1 27.6038 196.1 26.687V15.177C196.1 14.5658 195.9 14.0566 195.6 13.6491C195.3 13.2417 194.8 13.038 194.2 13.038C193.7 13.038 193.2 13.1398 192.7 13.2417C192.2 13.3435 191.6 13.6491 190.8 13.9547L186.2 15.7881C185.5 16.0937 185.1 16.3993 184.8 16.7049C184.5 17.0105 184.4 17.4179 184.4 17.9272C184.4 18.3346 184.5 18.6402 184.7 18.9458C184.9 19.2513 185.2 19.4551 185.5 19.6588L185.8 19.8625C186.1 20.0662 186.3 20.2699 186.4 20.4736C186.5 20.7792 186.6 21.1867 186.6 21.8997V29.1316C186.6 31.3725 187.2 33.1041 188.3 34.5301C189.4 35.8543 190.9 36.5673 192.7 36.5673C194 36.5673 195.4 36.1599 196.8 35.345C198 34.632 199.4 33.5116 201 31.9837C201 33.0023 201.4 33.919 202.1 34.7339C202.9 35.5487 203.9 35.9562 205 35.9562H209.8C210.4 35.9562 210.9 35.7524 211.3 35.4469C211.7 35.0394 211.9 34.632 211.9 34.0209C211.9 33.5116 211.8 33.206 211.6 32.9004C211.6 32.493 211.3 32.1874 211 31.8818Z" />
          <path
            d="M227.3 18.4365C228.9 18.4365 230.1 18.1309 230.8 17.6216C231.5 17.0104 231.9 16.3993 231.9 15.5844C231.9 15.0751 231.7 14.5658 231.4 14.2603C231 13.9547 230.6 13.8528 229.9 13.8528H225V9.77848C225 9.06547 224.8 8.45432 224.4 8.04688C224 7.63945 223.5 7.43573 222.9 7.43573C222.4 7.43573 221.8 7.63945 221.3 8.04688C220.8 8.45432 220.3 8.96361 219.8 9.57476L217.9 12.2231C217.5 12.8342 217.1 13.2417 216.8 13.4454C216.5 13.6491 216.2 13.751 215.9 13.751H215.2C214.6 13.751 214 13.9547 213.6 14.3621C213.2 14.7696 213 15.2788 213 15.7881C213 16.2974 213.1 16.7049 213.3 17.0104C213.5 17.316 213.8 17.6216 214.2 17.8253L214.6 18.029C215 18.2327 215.3 18.4365 215.4 18.742C215.6 19.0476 215.6 19.4551 215.6 20.0662V28.8261C215.6 31.4744 216.3 33.4097 217.6 34.632C218.9 35.8543 220.8 36.4655 223.4 36.4655C224.9 36.4655 226.3 36.1599 227.6 35.4469C228.9 34.7339 229.9 33.919 230.6 32.7985C231.3 31.6781 231.7 30.5576 231.7 29.3353C231.7 28.8261 231.6 28.4186 231.4 28.113C231.2 27.8075 231 27.7056 230.7 27.7056C230.4 27.7056 230.2 27.8075 229.9 27.9093C229.6 28.113 229.4 28.3168 229.1 28.6223C228.8 28.9279 228.5 29.1316 228.2 29.3353C227.9 29.5391 227.5 29.6409 227.1 29.6409C226.4 29.6409 225.9 29.4372 225.5 28.9279C225.2 28.4186 225 27.7056 225 26.687V18.3346H227.3V18.4365Z" />
          <path
            d="M258.8 31.8818L258.5 31.6781C258.3 31.4743 258.2 31.2706 258.1 30.965C258 30.6595 257.9 30.1502 257.9 29.3353V2.03717C257.9 1.42602 257.7 0.916728 257.4 0.509293C257.1 0.203717 256.6 0 256 0C255.5 0 255 0.101859 254.6 0.203717C254.2 0.305576 253.5 0.611152 252.7 0.916728L248.1 2.75018C247.4 3.05576 247 3.36134 246.7 3.66691C246.4 3.97249 246.3 4.37992 246.3 4.88922C246.3 5.29665 246.4 5.70409 246.6 5.9078C246.8 6.21338 247.1 6.4171 247.4 6.62081L247.7 6.82453C248 7.02825 248.2 7.23197 248.3 7.43568C248.4 7.6394 248.5 8.14869 248.5 8.86171V14.1584C247.3 13.5472 245.9 13.1398 244.3 13.1398C242.2 13.1398 240.2 13.6491 238.5 14.6677C236.8 15.6862 235.5 17.1123 234.5 18.9457C233.5 20.7792 233.1 23.0201 233.1 25.5665C233.1 27.7056 233.5 29.6409 234.3 31.2706C235.1 32.9004 236.1 34.2245 237.5 35.0394C238.8 35.9561 240.4 36.3636 242.1 36.3636C244.2 36.3636 246 35.7524 247.5 34.5301C248.1 34.1227 248.6 33.6134 249.1 33.0022C249.3 33.6134 249.6 34.1227 250.1 34.632C250.9 35.4468 251.8 35.7524 252.9 35.7524H257.7C258.3 35.7524 258.8 35.5487 259.2 35.2431C259.6 34.8357 259.8 34.4282 259.8 33.8171C259.8 33.3078 259.7 33.0022 259.5 32.6966C259.4 32.4929 259.1 32.1873 258.8 31.8818ZM246.5 30.8632C245.8 30.8632 245.2 30.6595 244.6 30.1502C244 29.7427 243.6 29.0297 243.3 28.113C243 27.1963 242.8 25.974 242.8 24.5479C242.8 23.1219 242.9 22.0015 243.2 21.0847C243.5 20.168 243.9 19.455 244.4 18.9457C244.9 18.4364 245.5 18.2327 246.2 18.2327C246.9 18.2327 247.5 18.4364 248.1 18.8439C248.2 18.9457 248.4 19.0476 248.5 19.1494V30.1502C247.9 30.5576 247.2 30.8632 246.5 30.8632Z" />
          <path
            d="M280.7 14.6677C278.9 13.6491 276.7 13.1398 274.2 13.1398C271.7 13.1398 269.4 13.6491 267.5 14.6677C265.6 15.6862 264.1 17.1123 263.1 18.8439C262 20.5755 261.5 22.5108 261.5 24.6498C261.5 26.9926 262 29.0297 263 30.8632C264 32.5948 265.5 34.0208 267.3 35.0394C269.2 36.058 271.3 36.5673 273.8 36.5673C276.3 36.5673 278.5 36.058 280.4 35.0394C282.3 34.0208 283.8 32.5948 284.9 30.8632C286 29.1316 286.5 27.1963 286.5 25.0572C286.5 22.7145 286 20.6773 285 18.9457C284 17.0104 282.5 15.5844 280.7 14.6677ZM276.9 30.5576C276.7 31.1688 276.3 31.5762 275.9 31.6781C275.4 31.7799 274.9 31.6781 274.4 31.2706C273.9 30.8632 273.4 30.252 272.9 29.2334C272.4 28.3167 272 26.9926 271.6 25.3628C271.2 23.7331 271 22.4089 270.9 21.2885C270.9 20.168 271 19.3531 271.2 18.8439C271.4 18.2327 271.8 17.9271 272.2 17.8253C272.7 17.7234 273.2 17.8253 273.6 18.2327C274 18.6401 274.6 19.2513 275.1 20.2699C275.6 21.1866 276 22.5108 276.4 24.1405C276.8 25.6684 277 26.9926 277.1 28.113C277.2 29.1316 277.1 30.0483 276.9 30.5576Z" />
          <path
            d="M307.7 14.6677C305.9 13.6491 303.7 13.1398 301.2 13.1398C298.7 13.1398 296.4 13.6491 294.5 14.6677C292.6 15.6862 291.1 17.1123 290.1 18.8439C289 20.5755 288.5 22.5108 288.5 24.6498C288.5 26.9926 289 29.0297 290 30.8632C291 32.5948 292.5 34.0208 294.3 35.0394C296.2 36.058 298.3 36.5673 300.8 36.5673C303.3 36.5673 305.5 36.058 307.4 35.0394C309.3 34.0208 310.8 32.5948 311.9 30.8632C313 29.1316 313.5 27.1963 313.5 25.0572C313.5 22.7145 313 20.6773 312 18.9457C310.9 17.0104 309.5 15.5844 307.7 14.6677ZM303.8 30.5576C303.6 31.1688 303.2 31.5762 302.8 31.6781C302.3 31.7799 301.8 31.6781 301.3 31.2706C300.8 30.8632 300.3 30.252 299.8 29.2334C299.3 28.3167 298.9 26.9926 298.5 25.3628C298.1 23.7331 297.9 22.4089 297.8 21.2885C297.8 20.168 297.9 19.3531 298.1 18.8439C298.3 18.2327 298.7 17.9271 299.1 17.8253C299.6 17.7234 300.1 17.8253 300.5 18.2327C300.9 18.6401 301.5 19.2513 302 20.2699C302.5 21.1866 302.9 22.5108 303.3 24.1405C303.7 25.6684 303.9 26.9926 304 28.113C304.2 29.1316 304.1 30.0483 303.8 30.5576Z" />
          <path
            d="M336 14.6677C335.2 13.6491 334 13.1398 332.6 13.1398C331.3 13.1398 330.1 13.5473 329.1 14.2603C328.1 14.9733 327.3 16.1956 326.7 17.7235C326.7 17.8253 326.6 17.9272 326.6 18.029L326.3 15.3807C326.2 14.464 326 13.8528 325.8 13.5473C325.6 13.2417 325.2 13.038 324.8 13.038C324.5 13.038 324.1 13.1398 323.6 13.2417C323.1 13.3435 322.6 13.6491 321.9 13.8528L317.2 15.6863C316.5 15.9919 316.1 16.2974 315.8 16.603C315.5 16.9086 315.4 17.316 315.4 17.8253C315.4 18.2328 315.5 18.5383 315.7 18.8439C315.9 19.1495 316.2 19.3532 316.5 19.5569L316.8 19.7606C317.1 19.9644 317.3 20.1681 317.4 20.3718C317.5 20.6774 317.6 21.0848 317.6 21.7978V29.1316C317.6 29.9465 317.6 30.4558 317.5 30.7614C317.4 31.067 317.3 31.2707 317.1 31.4744L316.8 31.6781C316.4 31.9837 316.2 32.1874 316 32.5948C315.8 32.9004 315.7 33.3078 315.7 33.7153C315.7 34.3264 315.9 34.8357 316.3 35.1413C316.7 35.5487 317.2 35.6506 317.8 35.6506H329C329.7 35.6506 330.2 35.4469 330.6 35.1413C331 34.7339 331.1 34.3264 331.1 33.7153C331.1 33.206 331 32.6967 330.7 32.3911C330.5 31.9837 330 31.78 329.5 31.6781L328.1 31.4744C327.7 31.3725 327.4 31.1688 327.2 30.7614C327 30.3539 326.9 29.8447 326.9 29.1316V22.9183C326.9 22.1034 327 21.3904 327.1 20.7792C327.2 20.2699 327.4 19.7606 327.6 19.5569C327.8 19.2513 328.1 19.1495 328.3 19.1495C328.6 19.1495 328.8 19.2513 329 19.4551C329.2 19.6588 329.2 19.9644 329.2 20.3718V21.3904C329.2 22.409 329.5 23.2238 330.2 23.835C330.8 24.4461 331.8 24.7517 333.1 24.7517C334.3 24.7517 335.3 24.2424 336.1 23.3257C336.9 22.409 337.2 20.9829 337.2 18.9458C337.2 16.9086 336.8 15.5844 336 14.6677Z" />
          <path
            d="M15 13.4453H13.2V23.8349H15C18.6 23.8349 20.2 22.2052 20.2 18.6401C20.3 15.2788 18.4 13.4453 15 13.4453Z" />
          <path
            d="M107.4 13.649C104.7 13.649 102.5 15.7881 102.5 18.5383C102.5 21.4922 104.8 23.6312 107.3 23.6312C109.9 23.6312 112.1 21.4922 112.1 18.5383C112.2 15.7881 110 13.649 107.4 13.649Z" />
          <path d="M56.9 21.1867V17.1123L54.5 21.1867H56.9Z" />
          <path
            d="M132.2 27.8074H128.1L121 17.6215V27.8074H116.3V21.4922C115.9 22.9182 115.1 24.2424 114.1 25.261C112.3 27.0944 109.9 28.113 107.3 28.113C104.6 28.113 102.3 27.1963 100.5 25.261V27.7056H90.6V9.37099H95.2V23.6312H99.4C98.5 22.1033 98 20.3717 98 18.3346C98 15.7881 99 13.4453 100.8 11.7137C102.6 9.88029 104.9 9.06542 107.5 9.06542C110 9.06542 112.3 9.98215 114.1 11.8156C115.2 12.936 116.1 14.2602 116.5 15.6862V9.47285H120.7L127.7 19.6587V9.47285H132.4V27.8074H132.2ZM89.1 27.8074H84.4V20.7792H79.6V27.8074H74.9V9.47285H79.6V16.3992H84.4V9.47285H89.1V27.8074ZM74.1 13.8528H70.5V27.8074H65.8V13.8528H62.2V9.47285H74.1V13.8528ZM61.2 27.8074H56.7V25.0572H53.1H52.6H52.5L52.4 25.1591L52.3 25.261L52.2 25.4647L52.1 25.5665L51.9 25.6684L51.7 25.7702C50.4 26.7888 47.8 28.4186 44 28.113C40.2 27.7056 35.5 24.6498 35.5 18.5383C35.5 15.5844 37.7 9.26914 45.1 9.16728C49.7 9.06542 52.3 12.223 52.7 12.7323V12.8342L50.3 16.7048L50.1 16.5011V16.2974L49.9 16.0937L49.7 15.89C48.8 14.9732 47.1 13.6491 45.1 13.7509C42.4 13.8528 40.3 16.0937 40.3 18.6401C40.3 20.9829 41.7 23.5294 45.3 23.5294C47.4 23.5294 48.8 22.0015 49.6 20.9829L49.7 20.7792C49.8 20.6773 50 20.2699 50.3 19.8624L56.3 9.57471H61.2V27.8074ZM35.1 13.5472H29.4V16.5011H34.3V20.4736H29.4V23.8349H35.1V27.8074H24.8V20.4736C24.5 22.5108 23.6 24.1405 22.2 25.5665C20.2 27.5018 18.4 27.8074 15.7 27.8074H8.5V9.47285H15C18 9.47285 20.1 9.88029 22.1 11.6119C23.5 12.936 24.4 14.6676 24.7 16.8067V9.47285H35V13.5472H35.1ZM0 36.6691H141.5V0.611145H0V36.6691Z" />
        </svg>
      </a>
    </div>
  );
}
