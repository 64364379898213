import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { ReactComponent as OfflineIcon } from 'icons/offline.svg';
import { ReactComponent as NetworkIcon } from 'icons/network.svg';

const useConnection = (displayMessages = false) => {
  const [ online, setOnline ] = useState(true);
  const [ bandwidth, setBandwidth ] = useState('good');

  useEffect(() => {
    const onOnline = e => setOnline(true);
    const onOffline = e => setOnline(false);

    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);

    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (connection) {
      if ([ 'cellular', 'slow-2g', '2g' ].includes(connection.effectiveType) && displayMessages) {
        toast.warn(<FormattedMessage id="network.slow" />, {
          icon: <NetworkIcon />,
        });

        setBandwidth('bad');
      }
    }

    return () => {
      window.removeEventListener('online', onOnline);
      window.removeEventListener('offline', onOffline);
    };
  }, []);

  useEffect(() => {
    if (online === false && displayMessages) {
      toast.error(<FormattedMessage id="network.offline" />, {
        icon: <OfflineIcon />,
      });
    }
  }, [online]);

  return { online, bandwidth };
};

export {
  useConnection,
};
