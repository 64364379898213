import './style/index.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import { Btn } from 'components/Btn';
import { FormattedMessage } from 'react-intl';
import { FieldError } from 'components/FieldError';
import { ReactComponent as ImportIcon } from 'icons/import.svg';
import { ReactComponent as ReplaceIcon } from 'icons/replace.svg';
import { toast } from 'react-toastify';
import { kml, gpx } from '@tmcw/togeojson';
import { getExtension } from 'utils/uri';
import { logError } from 'utils/error';
import { flattenFeature } from 'utils/geo';
import { Map } from 'components/Map';

const acceptedFormats = [
  '.gpx',
  '.kml',
  '.geojson',
  '.json',
];


export function FieldGeographical({ input, meta, example, label, placeholder, maxLength = 1, required, ...props }) {
  const [ files, setFiles ] = useState([]);
  const [ remainingFiles, setRemainingFiles ] = useState(maxLength - files.length);
  const field = useField(input.name);
  const lastNameSegment = input.name.split('.');

  const classNames = [
    lastNameSegment[lastNameSegment.length - 1],
    meta.touched && meta.error && 'error',
    props.disabled && 'disabled',
  ].filter(Boolean);

  const filesToDisplay = (files || []).filter(file => !file.deleted);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: props.accept ?? acceptedFormats.join(','),
    onDrop: async acceptedFiles => {
      let filesToAdd = acceptedFiles
        .filter((file, i) => i < remainingFiles)
        .map(file => ({
          file,
        }))
      ;

      // Add position
      filesToAdd = filesToAdd.map((file, i) => ({
        ...file,
        position: i + 1,
      }));

      setFiles(filesToAdd);

      const promises = [];

      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        const p = new Promise(resolve => {
          reader.onload = async event => {
            let content = event.target.result;

            switch (getExtension(file.name)) {
              case 'gpx':
                content = gpx(new DOMParser().parseFromString(event.target.result, 'text/xml'));
                break;

              case 'kml':
                content = kml(new DOMParser().parseFromString(event.target.result, 'text/xml'));
                break;

              case 'geojson':
              default:
                content = JSON.parse(event.target.result);
                break;
            }

            content = flattenFeature(content);

            resolve({
              data: content,
              info: {
                lastModified: file.lastModified,
                name: file.name,
                preview: file.preview,
                size: file.size,
                type: file.type,
              },
            });
          };
        });

        reader.readAsText(file);

        promises.push(p);
      });

      return Promise.all(promises)
        .then(files => field.input.onChange(maxLength > 1 ? files : files[0]))
        .catch((...args) => logError(...args) || toast.error(<FormattedMessage id="error.upload" />))
      ;
    }
  });

  useEffect(() => {
    setFiles(input.value);
  }, []);

  useEffect(() => {
    const filesToDisplay = (files || []).filter(file => !file.deleted);

    setRemainingFiles(maxLength - filesToDisplay.length);
  }, [ files, props.disabled ]);

  const hasData = Boolean(field?.input?.value?.data);

  return (
    <div className={`Field FieldGeographical ${classNames.join(' ')}`} onClick={e => input.onBlur(e)}>
      <div className="label">
        {label}
      </div>

      {hasData ? (
        <>
          <Map geojson={field.input.value.data} />

          <input {...getInputProps()} disabled={props.disabled} />
          <Btn variant={4} onClick={() => !props.disabled && open()} className="replace">
            <span className="icon">
              <ReplaceIcon />
            </span>
            <span className="label">
              <FormattedMessage id="gpx.replace" />
            </span>
          </Btn>
        </>
      ) : (
        <>
          <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} disabled={props.disabled} />

              <div className="dropzone-content">
                <div className="placeholder">
                  {placeholder}
                </div>

                <Btn variant={3}>
                  <span className="icon">
                    <ImportIcon />
                  </span>
                  <span className="label">
                    <FormattedMessage id="gpx.add" />
                  </span>
                </Btn>
              </div>
            </div>
          </section>
        </>
      )}

      <div className="field-footer">
        <FieldError name={input.name} />

        <div className="extra">
          {!required && (
            <div className="facultative"><FormattedMessage id="field.facultative" /></div>
          )}

          {maxLength > 1 && (
            <div className="maxlength">{`${filesToDisplay.length}/${maxLength}`}</div>
          )}
        </div>
      </div>

      {example && (
        <div className="example">{example}</div>
      )}
    </div>
  );
}

FieldGeographical.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  example: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  label: PropTypes.oneOfType([ PropTypes.func, PropTypes.node, PropTypes.string ]),
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
