export function formatDuration(minutes) {
  const hours = Math.floor(minutes / 60);
  minutes = Math.floor(minutes % 60);

  let displayMinutes = '';
  let displayHours = '';

  if (!hours) {
    displayHours = '';
  } else {
    displayHours = `${hours}h`;
  }

  if (!minutes) {
    displayMinutes = '';
  } else {
    displayMinutes = `${minutes}min`;
  }

  if (!minutes && !hours) {
    displayHours = '--:--';
  }

  return displayHours + displayMinutes;
}
